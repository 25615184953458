import React from 'react'
import { Redirect, Route } from 'react-router-dom';
import jwt_decode from "jwt-decode";
function AuthenticatedRoute({ component: Component, layout : Layout ,  ...rest }){
    const token =localStorage.getItem("token");
    const tokenDecode = jwt_decode(token);
    const tokenExpires = new Date(tokenDecode.exp * 1000).toLocaleDateString();
    const actualDate = new Date().toLocaleDateString();
    
    if(actualDate > tokenExpires){
      console.log("el token expiró");
    }else{
      console.log("el token tiene vigencia");
    }
    
  return(
    <Route
      {...rest}
      render={props => 
        token != null 
        ?
        <>
         <Layout>
          <Component {...props}/>
        </Layout>
        </>
        :
        <>
        <Redirect to="/login" />
        </>
      }
    />

    
  )
}
export default AuthenticatedRoute;