import React from 'react';
import axios from 'axios';
import Config from '../../../Config';
import { Link } from 'react-router-dom';
import { Container, Row, Col, Card, Button, Form, InputGroup } from "react-bootstrap";
var QRCode = require('qrcode.react');
function ListarPacientes(){

    const [dataPacientes, setDataPacientes] = React.useState(null);
    const [dataSearch, setDataSearch] = React.useState(null);
    React.useEffect(()=>{

        ObtenerPacientes();

    },[])

    async function ObtenerPacientes(){
        setDataPacientes(null);
        await axios.get(Config.url +'pacientes?_sort=createdAt:DESC&_limit=30', { headers: { 'Authorization': 'Bearer ' + localStorage.getItem('token') } } )
        .then(response=>{
            console.log(response);
            setDataPacientes(response.data);
        })
        .catch(error =>{
            console.log(error);
        })

    }

    const searchByCC = async () => {
        
        if (dataSearch != '') {
            // ?_where[_or][0][stars]=1&_where[_or][1][pricing_gt]=30
            let header = { headers: { 'Authorization': 'Bearer ' + localStorage.getItem('token') } };
          await axios.get(`${Config.url}pacientes?_where[_or][0][numero_documento]=${dataSearch}&_where[_or][1][nombre_completo_contains]=${dataSearch}`, header)
              
            .then(response => {
                setDataPacientes(response.data);
            })
            .catch(error => {
              console.log(error);
            })
    
        } else {
            ObtenerPacientes();
        }
    
    
    
      }

    return(
        <>
        <Row>
            <Col>
            <Card>
                <Card.Header>
                    <Row>
                        <Col>
                        <h4>LISTADO DE PACIENTES</h4>
                        </Col>
                        <Col className="text-right">
                        <Link to="/pacientes/nuevo">
                            <Button variant="primary">Nuevo Paciente</Button>
                            </Link>
                        </Col>
                    </Row>

                </Card.Header>
                <Card.Body>

                <Row>
                        <Col lg={12}>
                            <InputGroup className="mb-3">
                            <Form.Control type="text" value={dataSearch} onChange={(e)=>{setDataSearch(e.target.value)}} placeholder="Buscar" />
                            <Button variant="secondary" onClick={(e)=>{searchByCC();}}>Buscar</Button>
                            <Button variant="warning" onClick={(e)=>{ObtenerPacientes(); setDataSearch('');}}>Borrar Busqueda</Button>
                            </InputGroup>
                        </Col>                        
                    </Row>

                    <Row>
                        <Col>
                        <div className="table-responsive">
                                  <table className="table">
                                      <thead>
                                          <th>QR</th>
                                          <th>Fecha de Creacion</th>
                                          <th>Documento</th>
                                          <th>Nombre Completo</th>
                                          <th>Edad</th>
                                          <th>Telefono</th>
                                          <th>Opciones</th>

                                      </thead>
                                      <tbody>
                                        
                                          {dataPacientes ? dataPacientes.map((data,index)=>{
                                              return(
                                                  <tr key={index}>
                                                      <td>
                                                          <a href={Config.urlqrcode + data.id} target="_blank">
                                                          <QRCode value={Config.urlqrcode + data.id} style={{ width: 80, height: 80 }} />
                                                          </a>
                                                      </td>
                                                  <td>{new Date(data.createdAt).toLocaleDateString('es-ES')}</td>
                                                  <td>{data.tipo_documento} {data.numero_documento}</td>
                                                  <td>{data.nombre_completo}</td>
                                                  <td>{data.edad}</td>
                                                  <td>{data.telefono}</td>
                                                  <td>
                                                      <Link to={'/pacientes/ver/' + data._id} className="btn btn-success">Ver</Link>
                                                  </td>
                                                  </tr>
                                              )
                                          })
                                          : 
                                          'Cargando...'
                                        }
                                      </tbody>
                                  </table>
                              </div>
                        </Col>
                    </Row>

                </Card.Body>
                </Card>

            </Col>
        </Row>

        </>
    )

}

export default ListarPacientes;