import React from "react";
import axios from "axios";
import Config from "../../../Config";
import { useFormik } from "formik";
import { ToastContainer, toast } from "react-toastify";
import CanvasDraw from "react-canvas-draw";
import { useParams, useHistory } from 'react-router-dom';
import { Form , } from 'react-bootstrap'
function NuevaHistoria() {
  //Hooks
  const [loading, setLoading] = React.useState(false);
  const [idPaciente, setIdPaciente] = React.useState(null);
  const [PacienteExiste, setPacienteExiste] = React.useState(null);
  const [step, setStep] = React.useState(0);
  //Step 0 -> Buscar el paciente
  //Step 1 -> Crear el paciente si no existe o Mostrar los datos del paciente
  const DrawMedico = React.useRef(null);
  const DrawPaciente = React.useRef(null);
  const DrawConductor = React.useRef(null);
  const DrawTripulante = React.useRef(null);
  const history = useHistory();
  
  // Firma Medico
  const SaveDrawMedico = (e) => {
    e.preventDefault();
    const data = DrawMedico.current.getSaveData();
    localStorage.setItem('SaveDrawMedico', data);
    toast.success('Firma medico guardada exitosamente');
  }

  const ClearDrawMedico = (e) => {
    e.preventDefault();
    DrawMedico.current.clear();
  }

  //Firma Paciente
  const SaveDrawPaciente = (e) => {
    e.preventDefault();
    const data = DrawPaciente.current.getSaveData();
    localStorage.setItem('SaveDrawPaciente', data);
    toast.success('Firma paciente guardada exitosamente');
  }

  const ClearDrawPaciente = (e) => {
    e.preventDefault();
    DrawPaciente.current.clear();
  }

  //Firma Conductor
  const SaveDrawConductor = (e) => {
    e.preventDefault();
    const data = DrawConductor.current.getSaveData();
    localStorage.setItem('SaveDrawConductor', data);
    toast.success('Firma conductor guardada exitosamente');
  }

  const ClearDrawConductor = (e) => {
    e.preventDefault();
    DrawConductor.current.clear();
  }

  //Firma Tripulante
  const SaveDrawTripulante = (e) => {
    e.preventDefault();
    const data = DrawTripulante.current.getSaveData();
    localStorage.setItem('SaveDrawTripulante', data);
    toast.success('Firma tripulante guardada exitosamente');
  }
  const ClearDrawTripulante = (e) => {
    e.preventDefault();
    DrawTripulante.current.clear();
  }

  //Formik Errors validation
  // const validate = values => {
  //   const errors = {};
  //   if(PacienteExiste){

  //     if (!values.hora_llamada) {
  //       errors.hora_llamada = 'Campo obligatorio';
  //     }
  //     if (!values.fecha) {
  //       errors.fecha = 'Campo obligatorio';
  //     }
  //     if (!values.hora_llegada) {
  //       errors.hora_llegada = 'Campo obligatorio';
  //     }
  //     if (!values.zona) {
  //       errors.zona = 'Campo obligatorio';
  //     }
  //     if (!values.no_movil) {
  //       errors.no_movil = 'Campo obligatorio';
  //     }
  //     if (!values.placa_movil) {
  //       errors.placa_movil = 'Campo obligatorio';
  //     }
  //     if (!values.lugar_origen_ocurrencia) {
  //       errors.lugar_origen_ocurrencia = 'Campo obligatorio';
  //     }
  //     if (!values.motivo_descripcion_traslado) {
  //       errors.motivo_descripcion_traslado = 'Campo obligatorio';
  //     }
  //     if (!values.tipo_servicio) {
  //       errors.tipo_servicio = 'Campo obligatorio';
  //     }
  //     if (!values.ciudad_hc) {
  //       errors.ciudad_hc = 'Campo obligatorio';
  //     }
  //     if (!values.departamento_hc) {
  //       errors.departamento_hc = 'Campo obligatorio';
  //     }
  //     if (!values.servicio_prestado) {
  //       errors.servicio_prestado = 'Campo obligatorio';
  //     }
  //     if (!values.condicion_accidentado) {
  //       errors.condicion_accidentado = 'Campo obligatorio';
  //     }
  //     if (!values.tipo_vehiculo) {
  //       errors.tipo_vehiculo = 'Campo obligatorio';
  //     }
  //     if (!values.placa) {
  //       errors.placa = 'Campo obligatorio';
  //     }
  //     if (!values.marca) {
  //       errors.marca = 'Campo obligatorio';
  //     }
  //     if (!values.aseguradora) {
  //       errors.aseguradora = 'Campo obligatorio';
  //     }
  //     if (!values.poliza) {
  //       errors.poliza = 'Campo obligatorio';
  //     }
  //     if (!values.at) {
  //       errors.at = 'Campo obligatorio';
  //     }
  //     if (!values.no_poliza) {
  //       errors.no_poliza = 'Campo obligatorio';
  //     }
  //     if (!values.escala_glassgow) {
  //       errors.escala_glassgow = 'Campo obligatorio';
  //     }
  //     if (!values.frecuencia_respiratoria) {
  //       errors.frecuencia_respiratoria = 'Campo obligatorio';
  //     }
  //     if (!values.frecuencia_cardiaca) {
  //       errors.frecuencia_cardiaca = 'Campo obligatorio';
  //     }
  //     if (!values.saturacion) {
  //       errors.saturacion = 'Campo obligatorio';
  //     }
  //     if (!values.temperatura) {
  //       errors.temperatura = 'Campo obligatorio';
  //     }
  //     if (!values.oxigeno) {
  //       errors.oxigeno = 'Campo obligatorio';
  //     }
  //     if (!values.presion_arterial_diastolica) {
  //       errors.presion_arterial_diastolica = 'Campo obligatorio';
  //     }
  //     if (!values.presion_arterial_sistolica) {
  //       errors.presion_arterial_sistolica = 'Campo obligatorio';
  //     }
  //     if (!values.analisis_observaciones_traslado) {
  //       errors.analisis_observaciones_traslado = 'Campo obligatorio';
  //     }
  //     if (!values.nombre_institucion_traslado_final) {
  //       errors.nombre_institucion_traslado_final = 'Campo obligatorio';
  //     }
  //     if (!values.servicio_traslado_final) {
  //       errors.servicio_traslado_final = 'Campo obligatorio';
  //     }
  //     if (!values.habilitacion_cama_traslado_final) {
  //       errors.habilitacion_cama_traslado_final = 'Campo obligatorio';
  //     }
  //     if (!values.ciudad_traslado_final) {
  //       errors.ciudad_traslado_final = 'Campo obligatorio';
  //     }
  //     if (!values.departamento_traslado_final) {
  //       errors.departamento_traslado_final = 'Campo obligatorio';
  //     }
  //     if (!values.hora_traslado_final) {
  //       errors.hora_traslado_final = 'Campo obligatorio';
  //     }
  //     if (!values.fecha_traslado_final) {
  //       errors.fecha_traslado_final = 'Campo obligatorio';
  //     }
  //     if (!values.autoriza_traslado) {
  //       errors.autoriza_traslado = 'Campo obligatorio';
  //     }
  //     if (!values.registro_medico) {
  //       errors.registro_medico = 'Campo obligatorio';
  //     }
  //     if (!values.documento_paciente) {
  //       errors.documento_paciente = 'Campo obligatorio';
  //     }
  //     if (!values.documento_tripulante_ambulancia) {
  //       errors.documento_tripulante_ambulancia = 'Campo obligatorio';
  //     }
  //     if (!values.documento_conductor_ambulancia) {
  //       errors.documento_conductor_ambulancia = 'Campo obligatorio';
  //     }
  //   }

  //   console.log(errors);
  //   return errors;
    

    
  // };

  const validate = values => {
    const errors = {};
    if(!PacienteExiste){
      if (!values.tipo_documento) {
        errors.tipo_documento = 'Campo obligatorio';
      }
      
      if (!values.numero_documento) {
        errors.numero_documento = 'Campo obligatorio';
      }
      if (!values.nombre_completo) {
        errors.nombre_completo = 'Campo obligatorio';
      }
      if (!values.fecha_nacimiento) {
        errors.fecha_nacimiento = 'Campo obligatorio';
      }
      if (!values.edad) {
        errors.edad = 'Campo obligatorio';
      }
      if (!values.sexo) {
        errors.sexo = 'Campo obligatorio';
      }
      if (!values.eps) {
        errors.eps = 'Campo obligatorio';
      }
      if (!values.direccion) {
        errors.direccion = 'Campo obligatorio';
      }
      if (!values.telefono) {
        errors.telefono = 'Campo obligatorio';
      }
      
      if (!values.ciudad) {
        errors.ciudad = 'Campo obligatorio';
      }
      if (!values.departamento) {
        errors.departamento = 'Campo obligatorio';
      }      
    }

    if(PacienteExiste){
      if (!values.origen_del_servicio) {
        errors.origen_del_servicio = 'Campo obligatorio';
      }
    }

    

    console.log(errors);
    return errors;
    

    
  };

  //Formik
  const formik = useFormik({
    initialValues: {},
    validate,
    async onSubmit(values) {
      //Función para crear el paciente en caso que no exista
      if (!PacienteExiste) {
        setLoading(true);
        await axios
          .post(Config.url + "pacientes", values, { headers: { 'Authorization': 'Bearer ' + localStorage.getItem('token') } } )
          .then((response) => {
            console.log(response);
            localStorage.removeItem('SaveDrawMedico');
            localStorage.removeItem('SaveDrawPaciente');
            localStorage.removeItem('SaveDrawTripulante');
            localStorage.removeItem('SaveDrawConductor');
            toast.success('Paciente creado exitosamente');
            setPacienteExiste(true);
            setLoading(false);
            setIdPaciente(response.data._id);
            setStep(1);
          })
          .catch((error) => {
            setLoading(false);
            console.error(error);
            toast.error('No se ha podido crear el paciente');
          });
      }
      //Función que guarda la historia clínica en caso de existir el paciente
      if (PacienteExiste && idPaciente) {
        //Validar que tenga las firmas correspondientes
        if(
          localStorage.getItem('SaveDrawMedico')&&
          localStorage.getItem('SaveDrawPaciente') &&
          localStorage.getItem('SaveDrawTripulante') && 
          localStorage.getItem('SaveDrawConductor')
        ){
          setLoading(true);
          let paramsToApi = {
            hora_llamada: formik.values.hora_llamada,
            origen_del_servicio: formik.values.origen_del_servicio,
            fecha: formik.values.fecha,
            hora_llegada: formik.values.hora_llegada,
            zona: formik.values.zona,
            no_movil: formik.values.no_movil,
            placa_movil: formik.values.placa_movil,
            lugar_origen_ocurrencia: formik.values.lugar_origen_ocurrencia,
            motivo_descripcion_traslado:
              formik.values.motivo_descripcion_traslado,
            tipo_servicio: formik.values.tipo_servicio,
            ciudad_hc: formik.values.ciudad_hc,
            departamento_hc: formik.values.departamento_hc,
            servicio_prestado: formik.values.servicio_prestado,
            condicion_accidentado: formik.values.condicion_accidentado,
            tipo_vehiculo: formik.values.tipo_vehiculo,
            placa: formik.values.placa,
            marca: formik.values.marca,
            aseguradora: formik.values.aseguradora,
            poliza: formik.values.poliza,
            at: formik.values.at,
            no_poliza: formik.values.no_poliza,
            escala_glassgow: formik.values.escala_glassgow,
            frecuencia_respiratoria: formik.values.frecuencia_respiratoria,
            frecuencia_cardiaca: formik.values.frecuencia_cardiaca,
            saturacion: formik.values.saturacion,
            temperatura: formik.values.temperatura,
            glucometria: formik.values.glucometria,
            oxigeno: formik.values.oxigeno,
            presion_arterial_diastolica:
              formik.values.presion_arterial_diastolica,
            presion_arterial_sistolica: formik.values.presion_arterial_sistolica,
            analisis_observaciones_traslado:
              formik.values.analisis_observaciones_traslado,
            nombre_institucion_traslado_final:
              formik.values.nombre_institucion_traslado_final,
            servicio_traslado_final: formik.values.servicio_traslado_final,
            habilitacion_cama_traslado_final:
              formik.values.habilitacion_cama_traslado_final,
            ciudad_traslado_final: formik.values.ciudad_traslado_final,
            departamento_traslado_final:
              formik.values.departamento_traslado_final,
            hora_traslado_final: formik.values.hora_traslado_final,
            fecha_traslado_final: formik.values.fecha_traslado_final,
            autoriza_traslado: formik.values.autoriza_traslado,
            firma_medico: localStorage.getItem('SaveDrawMedico'),
            registro_medico: formik.values.registro_medico,
            firma_paciente: localStorage.getItem('SaveDrawPaciente'),
            documento_paciente: formik.values.documento_paciente,
            tripulante_ambulancia: localStorage.getItem('SaveDrawTripulante'),
            documento_tripulante_ambulancia:
              formik.values.documento_tripulante_ambulancia,
            conductor_ambulancia: localStorage.getItem('SaveDrawConductor'),
            documento_conductor_ambulancia:
              formik.values.documento_conductor_ambulancia,
            paciente: idPaciente,
          };
          await axios
            .post(Config.url + "historia-clinicas", paramsToApi, { headers: { 'Authorization': 'Bearer ' + localStorage.getItem('token') } } )
            .then((response) => {
              console.log(response);
              setPacienteExiste(true);
              setLoading(false);
              setStep(1);
              toast.success('Historia clinica creada con exito');
              history.push('/historias/ver/'+response.data._id)
            })
            .catch((error) => {
              console.error(error);
              setLoading(false);
              toast.error('No se ha podido crear la historia clinica');
            });

        }else{
          toast.error('Las firmas son obligatorias');
          setLoading(false);
        }
        
      }

      console.log(values);
    },
  });

  //Functions
  async function BuscarPaciente() {
    localStorage.removeItem('SaveDrawMedico');
    localStorage.removeItem('SaveDrawPaciente');
    localStorage.removeItem('SaveDrawTripulante');
    localStorage.removeItem('SaveDrawConductor');
    setLoading(true);
    let tipoDocumento = formik.values.tipo_documento;
    let numeroDocumento = formik.values.numero_documento;
    console.log(tipoDocumento);

    const headers = {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("siteToken"),
      },
    };
    await axios
      .get(Config.url +"pacientes?tipo_documento=" +tipoDocumento +"&numero_documento=" +numeroDocumento +"", { headers: { 'Authorization': 'Bearer ' + localStorage.getItem('token') } } )
      .then((response) => {
        setLoading(false);
        console.log(response);
        let data = response.data[0];
        if (response.data.length > 0) {
          setIdPaciente(data._id);
          const fields = [
            "nombre_completo",
            "tipo_documento",
            "numero_documento",
            "fecha_nacimiento",
            "edad",
            "sexo",
            "eps",
            "estado_civil",
            "direccion",
            "ocupacion",
            "telefono",
            "ciudad",
            "departamento",
            "nombre_acompa",
            "parentesco",
            "diagnostico_enfermedades",
            "antecedentespatologicos_alergias",
            "antecedentespatologicos_familiares",
            "antecedentespatologicos_cirugias",
            "antecedentespatologicos_medicamentos",
          ];
          fields.forEach((field) =>
            formik.setFieldValue(field, data[field], false)
          );
          setPacienteExiste(true);
          setStep(1);
        } else {
          toast.error("Paciente no encontrado");
          const fields = [
            "nombre_completo",
            "fecha_nacimiento",
            "edad",
            "sexo",
            "eps",
            "estado_civil",
            "direccion",
            "ocupacion",
            "telefono",
            "ciudad",
            "departamento",
            "nombre_acompa",
            "parentesco",
            "diagnostico_enfermedades",
            "antecedentespatologicos_alergias",
            "antecedentespatologicos_familiares",
            "antecedentespatologicos_cirugias",
            "antecedentespatologicos_medicamentos",
          ];
          fields.forEach((field) => formik.setFieldValue(field, "", false));

          setPacienteExiste(false);
          setStep(1);
        }
      })
      .catch((error) => {
        setLoading(false);
        console.error(error);
        setPacienteExiste(false);
        setStep(1);
      });
  }

  return (
    <>
      <ToastContainer />
      <form onSubmit={formik.handleSubmit}>
        {/* INICIO DEL FORMULARIO */}

        {/* DATOS DEL PACIENTE */}
        <div className="row layout-top-spacing">
          <div className="col-xl-12 col-lg-12 col-md-12 layout-spacing">
            <div className="statbox widget box box-shadow">
              <div className="widget-header">
                <div className="row">
                  <div className="col-xl-12 col-md-12 col-sm-12 col-12">
                    <h4>DATOS DEL PACIENTE</h4>
                  </div>
                </div>
              </div>
              <div className="widget-content widget-content-area">
                <div className="row">
                  <div className="col-lg-12 d-none d-md-block d-lg-block">
                    <div className="input-group mb-4">
                      <div className="input-group-prepend tipo_documento">
                        <select
                          id="unique_tipo_documento"
                          className="form-control"
                          onChange={formik.handleChange}
                          name="tipo_documento"
                          value={formik.values.tipo_documento || ""}
                        >
                          <option>Tipo Documento (*)</option>
                          <option value="CC">Cédula de Ciudadanía</option>
                          <option value="CE">Cédula de Extranjería</option>
                          <option value="TI">Tarjeta de Identidad</option>
                          <option value="NUIP">Numero Unico de Identificación (NUIP)</option>
                          <option value="REGC">Registro Civil</option>
                          
                          
                        </select>
                        {formik.errors.tipo_documento && formik.touched.lastName ?
                          <>
                            <div className="alert alert-warning mb-4" role="alert">
                              <strong>Atención!</strong> {formik.errors.tipo_documento}
                            </div>
                          </>
                          :
                          null
                          }
                      </div>
                      <input
                        id="unique_numero_documento"
                        type="number"
                        className="form-control"
                        onChange={formik.handleChange}
                        name="numero_documento"
                        value={formik.values.numero_documento || ""}
                      />
                      {formik.errors.numero_documento && formik.touched.numero_documento ?
                          <>
                            <div className="alert alert-warning mb-4" role="alert">
                              <strong>Atención!</strong> {formik.errors.numero_documento}
                            </div>
                          </>
                          :
                          null
                          }
                      <div className="input-group-append btnsearch">
                        {formik.values.tipo_documento &&
                          formik.values.numero_documento && (
                            <button
                              id="unique_button_search"
                              type="button"
                              onClick={BuscarPaciente}
                              className="btn btn-outline-info"
                              disabled={loading}
                            >
                              {!loading ? "Buscar" : "Buscando..."}
                            </button>
                          )}
                      </div>
                    </div>
                  </div>

                  {/* TIPO DOCUMENTO SOLO MOVIL*/}
                  <div className="col-lg-12 d-md-none d-lg-none d-block">
                  <div className="form-group mb-3 tipo_documento">
                          <label>Tipo Documento (*)</label>
                          <select
                            className="form-control"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            name="tipo_documento"
                            autoComplete={false}
                            value={formik.values.tipo_documento || ""}
                          >
                            <option>Seleccionar Opción</option>
                            <option value="CC">Cédula de Ciudadanía</option>
                            <option value="CE">Cédula de Extranjería</option>
                            <option value="TI">Tarjeta de Identidad</option>
                            <option value="NUIP">Numero Unico de Identificación (NUIP)</option>
                            <option value="REGC">Registro Civil</option>
                          </select>
                          {formik.errors.tipo_documento && formik.touched.tipo_documento ?
                          <>
                            <div className="alert alert-warning mb-4" role="alert">
                              <strong>Atención!</strong> {formik.errors.tipo_documento}
                            </div>
                          </>
                          :
                          null
                          }
                        </div>
                  </div>

                  {/* NUMERO DOCUMENTO */}
                  <div className="col-lg-12 d-md-none d-lg-none d-block">
                  <div className="form-group mb-3 numero_documento">
                          <label>Numero Documento (*)</label>
                          <input
                            type="number"
                            className="form-control"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            name="numero_documento"
                            autoComplete={false}
                            value={formik.values.numero_documento || ""}
                          />
                          {formik.errors.numero_documento && formik.touched.numero_documento ?
                          <>
                            <div className="alert alert-warning mb-4" role="alert">
                              <strong>Atención!</strong> {formik.errors.numero_documento}
                            </div>
                          </>
                          :
                          null
                          }
                        </div>
                  </div>

                  {/* BOTON BUSCAR */}
                  <div className="col-lg-12 d-md-none d-lg-none d-block">
                  <div className="form-group mb-3 btnbuscar">
                  {formik.values.tipo_documento &&
                          formik.values.numero_documento && (
                            <button
                              type="button"
                              onClick={BuscarPaciente}
                              className="btn btn-outline-info"
                              disabled={loading}
                            >
                              {!loading ? "Buscar" : "Buscando..."}
                            </button>
                          )}
                        </div>
                  </div>

                  {step == 1 && (
                    <>
                      <div className="col-12">
                      {!PacienteExiste && 
                      <div className="alert alert-info mb-4" role="alert">
                        <strong>Atención!</strong> El paciente no se encuentra creado en el sistema, crea al paciente y seguido se habilitará el formulario para crear su historia clínica <br/> <strong>NO ACTUALICE LA PÁGINA, NO SE SALGA DE LA PÁGINA, DE LO CONTRARIO VA PERDER LA INFORMACIÓN ESCRITA, los campos marcados (*) son obligatorios</strong>
                      </div>
                      }
                        <div className="form-group mb-3">
                          <label>Nombre Completo (*)</label>
                          <input
                            type="text"
                            className="form-control"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            name="nombre_completo"
                            autoComplete={false}
                            value={formik.values.nombre_completo || ""}
                          />
                          {formik.errors.nombre_completo && formik.touched.nombre_completo ?
                          <>
                            <div className="alert alert-warning mb-4" role="alert">
                              <strong>Atención!</strong> {formik.errors.nombre_completo}
                            </div>
                          </>
                          :
                          null
                          }
                        </div>
                      </div>

                      <div className="col-12">
                        <div className="form-group mb-3">
                          <label>Fecha de Nacimiento (*)</label>
                          <input
                            type="date"
                            className="form-control"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            name="fecha_nacimiento"
                            autoComplete={false}
                            value={formik.values.fecha_nacimiento || ""}
                          />
                          {formik.errors.fecha_nacimiento && formik.touched.fecha_nacimiento ?
                          <>
                            <div className="alert alert-warning mb-4" role="alert">
                              <strong>Atención!</strong> {formik.errors.fecha_nacimiento}
                            </div>
                          </>
                          :
                          null
                          }
                        </div>
                      </div>

                      <div className="col-12">
                        <div className="form-group mb-3">
                          <label>Edad (*)</label>
                          <input
                            type="text"
                            className="form-control"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            autoComplete={false}
                            name="edad"
                            value={formik.values.edad || ""}
                          />
                          {formik.errors.edad && formik.touched.edad ?
                          <>
                            <div className="alert alert-warning mb-4" role="alert">
                              <strong>Atención!</strong> {formik.errors.edad}
                            </div>
                          </>
                          :
                          null
                          }
                        </div>
                      </div>

                      <div className="col-12">
                        <div className="form-group mb-3">
                          <label>Sexo (*)</label>
                          <select
                            className="form-control"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            name="sexo"
                            value={formik.values.sexo || ""}
                          >
                            <option>Seleccionar Opción</option>
                            <option value="Masculino">Masculino</option>
                            <option value="Femenino">Femenino</option>
                            <option value="Sin Definir">Sin Definir</option>
                          </select>
                          {formik.errors.sexo  && formik.touched.sexo?
                          <>
                            <div className="alert alert-warning mb-4" role="alert">
                              <strong>Atención!</strong> {formik.errors.sexo}
                            </div>
                          </>
                          :
                          null
                          }
                        </div>
                      </div>

                      <div className="col-12">
                        <div className="form-group mb-3">
                          <label>EPS (*)</label>
                          <input
                            type="text"
                            className="form-control"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            autoComplete={false}
                            name="eps"
                            value={formik.values.eps || ""}
                          />
                          {formik.errors.eps && formik.touched.eps ?
                          <>
                            <div className="alert alert-warning mb-4" role="alert">
                              <strong>Atención!</strong> {formik.errors.eps}
                            </div>
                          </>
                          :
                          null
                          }
                        </div>
                      </div>

                      <div className="col-12">
                        <div className="form-group mb-3">
                          <label>Estado Civil</label>
                          <select
                            className="form-control"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            name="estado_civil"
                            value={formik.values.estado_civil || ""}
                          >
                            <option>Seleccionar Opción</option>
                            <option value="Soltero">Soltero</option>
                            <option value="Casado">Casado</option>
                            <option value="Union Libre">Union Libre</option>
                            <option value="Viudo">Viudo</option>
                          </select>
                          </div>
                      </div>

                      <div className="col-12">
                        <div className="form-group mb-3">
                          <label>Dirección (*)</label>
                          <input
                            type="text"
                            className="form-control"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            autoComplete={false}
                            name="direccion"
                            value={formik.values.direccion || ""}
                          />
                          {formik.errors.direccion && formik.touched.direccion ?
                          <>
                            <div className="alert alert-warning mb-4" role="alert">
                              <strong>Atención!</strong> {formik.errors.direccion}
                            </div>
                          </>
                          :
                          null
                          }
                        </div>
                      </div>

                      <div className="col-12">
                        <div className="form-group mb-3">
                          <label>Ocupación</label>
                          <input
                            type="text"
                            className="form-control"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            autoComplete={false}
                            name="ocupacion"
                            value={formik.values.ocupacion || ""}
                          />
                          </div>
                      </div>

                      <div className="col-12">
                        <div className="form-group mb-3">
                          <label>Teléfono (*)</label>
                          <input
                            type="number"
                            className="form-control"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            autoComplete={false}
                            name="telefono"
                            value={formik.values.telefono || ""}
                          />
                          {formik.errors.telefono && formik.touched.telefono ?
                          <>
                            <div className="alert alert-warning mb-4" role="alert">
                              <strong>Atención!</strong> {formik.errors.telefono}
                            </div>
                          </>
                          :
                          null
                          }
                        </div>
                      </div>

                      <div className="col-12">
                        <div className="form-group mb-3">
                          <label>Ciudad (*)</label>
                          <input
                            type="text"
                            className="form-control"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            autoComplete={false}
                            name="ciudad"
                            value={formik.values.ciudad || ""}
                          />
                          {formik.errors.ciudad && formik.touched.ciudad ?
                          <>
                            <div className="alert alert-warning mb-4" role="alert">
                              <strong>Atención!</strong> {formik.errors.ciudad}
                            </div>
                          </>
                          :
                          null
                          }
                        </div>
                      </div>

                      <div className="col-12">
                        <div className="form-group mb-3">
                          <label>Departamento (*)</label>
                          <input
                            type="text"
                            className="form-control"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            autoComplete={false}
                            name="departamento"
                            value={formik.values.departamento || ""}
                          />
                          {formik.errors.departamento && formik.touched.departamento ?
                          <>
                            <div className="alert alert-warning mb-4" role="alert">
                              <strong>Atención!</strong> {formik.errors.departamento}
                            </div>
                          </>
                          :
                          null
                          }
                        </div>
                      </div>

                      <div className="col-12">
                        <div className="form-group mb-3">
                          <label>Nombre Acompañante</label>
                          <input
                            type="text"
                            className="form-control"
                            onChange={formik.handleChange}
                            autoComplete={false}
                            name="nombre_acompa"
                            value={formik.values.nombre_acompa || ""}
                          />
                          
                        </div>
                      </div>

                      <div className="col-12">
                        <div className="form-group mb-3">
                          <label>Parentezco</label>
                          <input
                            type="text"
                            className="form-control"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            autoComplete={false}
                            name="parentesco"
                            value={formik.values.parentesco || ""}
                          />
                          
                        </div>
                      </div>

                      <div className="col-12">
                        <div className="form-group mb-3">
                          <label>Diagnóstico de Enfermedades</label>
                          <input
                            type="text"
                            className="form-control"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            autoComplete={false}
                            name="diagnostico_enfermedades"
                            value={formik.values.diagnostico_enfermedades || ""}
                          />
                        </div>
                      </div>

                      <h3>Antecedentes Patológicos</h3>

                      <div className="col-12">
                        <div className="form-group mb-3">
                          <label>Alergias</label>
                          <input
                            type="text"
                            className="form-control"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            autoComplete={false}
                            name="antecedentespatologicos_alergias"
                            value={
                              formik.values.antecedentespatologicos_alergias ||
                              ""
                            }
                          />
                          
                        </div>
                      </div>

                      <div className="col-12">
                        <div className="form-group mb-3">
                          <label>Familiares</label>
                          <input
                            type="text"
                            className="form-control"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            autoComplete={false}
                            name="antecedentespatologicos_familiares"
                            value={
                              formik.values
                                .antecedentespatologicos_familiares || ""
                            }
                          />
                          
                        </div>
                      </div>

                      <div className="col-12">
                        <div className="form-group mb-3">
                          <label>Cirugias</label>
                          <input
                            type="text"
                            className="form-control"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            autoComplete={false}
                            name="antecedentespatologicos_cirugias"
                            value={
                              formik.values.antecedentespatologicos_cirugias ||
                              ""
                            }
                          />
                          </div>
                      </div>

                      <div className="col-12">
                        <div className="form-group mb-3">
                          <label>Medicamentos</label>
                          <input
                            type="text"
                            className="form-control"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            autoComplete={false}
                            name="antecedentespatologicos_medicamentos"
                            value={
                              formik.values
                                .antecedentespatologicos_medicamentos || ""
                            }
                          />
                          
                        </div>
                      </div>
                    </>
                  )}
                </div>
                {!PacienteExiste && step == 1 && (
                  <button type="submit" className="btn btn-success">
                    {!loading ? 'Crear Paciente' : 'Creando paciente...'}
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
        {PacienteExiste && (
          <>
            {/* DATOS DEL TRASLADO */}
            <div className="row layout-top-spacing">
              <div className="col-xl-12 col-lg-12 col-md-12 col-12 layout-spacing">
                <div className="statbox widget box box-shadow">
                  <div className="widget-header">
                    <div className="row">
                      <div className="col-xl-12 col-md-12 col-sm-12 col-12">
                        <h4>DATOS DEL TRASLADO</h4>
                      </div>
                    </div>
                  </div>
                  <div className="widget-content widget-content-area">
                    <div className="row">
                    <div className="col-12">
                        <div className="form-group mb-3">
                          <label>Origen del servicio</label>
                          <select
                            className="form-control"
                            onChange={formik.handleChange}
                            name="origen_del_servicio"

                            value={formik.values.origen_del_servicio || ""}
                          >
                            <option>Seleccione una opción</option>
                            <option>SOAT</option>
                            <option>CCQ</option>
                            <option>ARL</option>
                            <option>Particular</option>
                            <option>Convenio</option>  
                            <option>Fundaciones</option>  
                            <option>Otro</option>  
                          </select>
                          {formik.errors.origen_del_servicio ?
                          <>
                            <div className="alert alert-warning mb-4" role="alert">
                              <strong>Atención!</strong> {formik.errors.origen_del_servicio}
                            </div>
                          </>
                          :
                          null
                          }
                        </div>
                      </div>
                      <div className="col-12">
                        <div className="form-group mb-3">
                          <label>Ciudad</label>
                          <input
                            type="text"
                            className="form-control"
                            onChange={formik.handleChange}
                            name="ciudad_hc"
                            autoComplete={false}
                            value={formik.values.ciudad_hc || ""}
                          />
                          {formik.errors.ciudad_hc ?
                          <>
                            <div className="alert alert-warning mb-4" role="alert">
                              <strong>Atención!</strong> {formik.errors.ciudad_hc}
                            </div>
                          </>
                          :
                          null
                          }
                        </div>
                      </div>
                      <div className="col-12">
                        <div className="form-group mb-3">
                          <label>Departamento</label>
                          <input
                            type="text"
                            className="form-control"
                            onChange={formik.handleChange}
                            name="departamento_hc"
                            autoComplete={false}
                            value={formik.values.departamento_hc || ""}
                          />
                          {formik.errors.departamento_hc ?
                          <>
                            <div className="alert alert-warning mb-4" role="alert">
                              <strong>Atención!</strong> {formik.errors.departamento_hc}
                            </div>
                          </>
                          :
                          null
                          }
                        </div>
                      </div>
                      <div className="col-12">
                        <div className="form-group mb-3">
                          <label>Hora Llamado</label>
                          <input
                            type="time"
                            className="form-control"
                            onChange={formik.handleChange}
                            name="hora_llamada"
                            autoComplete={false}
                            value={formik.values.hora_llamada || ""}
                          />
                          {formik.errors.hora_llamada ?
                          <>
                            <div className="alert alert-warning mb-4" role="alert">
                              <strong>Atención!</strong> {formik.errors.hora_llamada}
                            </div>
                          </>
                          :
                          null
                          }
                          </div>
                      </div>
                      <div className="col-12">
                        <div className="form-group mb-3">
                          <label>Fecha</label>
                          <input
                            type="date"
                            className="form-control"
                            onChange={formik.handleChange}
                            name="fecha"
                            value={formik.values.fecha || ""}
                          />
                          {formik.errors.fecha ?
                          <>
                            <div className="alert alert-warning mb-4" role="alert">
                              <strong>Atención!</strong> {formik.errors.fecha}
                            </div>
                          </>
                          :
                          null
                          }
                        </div>
                      </div>
                      <div className="col-12">
                        <div className="form-group mb-3">
                          <label>Hora Llegada</label>
                          <input
                            type="time"
                            className="form-control"
                            onChange={formik.handleChange}
                            name="hora_llegada"
                            value={formik.values.hora_llegada || ""}
                          />
                          {formik.errors.hora_llegada ?
                          <>
                            <div className="alert alert-warning mb-4" role="alert">
                              <strong>Atención!</strong> {formik.errors.hora_llegada}
                            </div>
                          </>
                          :
                          null
                          }
                        </div>
                      </div>
                      <div className="col-12">
                        <div className="form-group mb-3">
                          <label>Zona</label>
                          <select
                            className="form-control"
                            onChange={formik.handleChange}
                            name="zona"
                            
                            value={formik.values.zona || ""}
                          >
                            <option>Seleccione una opción</option>
                            <option value="urbana">Urbana</option>
                            <option value="rural">Rural</option>
                          </select>
                          {formik.errors.zona ?
                          <>
                            <div className="alert alert-warning mb-4" role="alert">
                              <strong>Atención!</strong> {formik.errors.zona}
                            </div>
                          </>
                          :
                          null
                          }
                        </div>
                      </div>
                      <div className="col-12">
                        <div className="form-group mb-3">
                          <label>No Móvil</label>
                          <select
                            className="form-control"
                            onChange={formik.handleChange}
                            name="no_movil"

                            value={formik.values.no_movil || ""}
                          >
                            <option>Seleccione una opción</option>
                            <option>Seleccione una opción</option>
                            <option>43</option>
                            <option>32</option>
                            <option>168</option>
                            <option>167</option>
                            <option>177</option>  
                          </select>
                          {formik.errors.no_movil ?
                          <>
                            <div className="alert alert-warning mb-4" role="alert">
                              <strong>Atención!</strong> {formik.errors.no_movil}
                            </div>
                          </>
                          :
                          null
                          }
                        </div>
                      </div>
                      <div className="col-12">
                        <div className="form-group mb-3">
                          <label>Placa Móvil</label>
                          <select
                            className="form-control"
                            onChange={formik.handleChange}
                            name="placa_movil"
                            value={formik.values.placa_movil || ""}
                          >
                            <option>Seleccione una opción</option>
                            <option value="RIY 482">RIY 482</option>
                            <option value="GHA 470">GHA 470</option>
                            <option value="TVB 304">TVB 304</option>
                            <option value="RHK 345">RHK 345</option>
                            <option value="BWZ 949">BWZ 949</option>                       
                            <option value="DTV 016">DTV 016</option>                       
                            
                            
                          </select>
                          {formik.errors.placa_movil ?
                          <>
                            <div className="alert alert-warning mb-4" role="alert">
                              <strong>Atención!</strong> {formik.errors.placa_movil}
                            </div>
                          </>
                          :
                          null
                          }
                        </div>
                      </div>
                      <div className="col-12">
                        <div className="form-group mb-3">
                          <label>Lugar Origen Y/O Ocurrencia</label>
                          <input
                            type="text"
                            className="form-control"
                            onChange={formik.handleChange}
                            name="lugar_origen_ocurrencia"
                            autoComplete={false}
                            value={formik.values.lugar_origen_ocurrencia || ""}
                          />
                          {formik.errors.lugar_origen_ocurrencia ?
                          <>
                            <div className="alert alert-warning mb-4" role="alert">
                              <strong>Atención!</strong> {formik.errors.lugar_origen_ocurrencia}
                            </div>
                          </>
                          :
                          null
                          }
                        </div>
                      </div>
                      <div className="col-12">
                        <div className="form-group mb-3">
                          <label>Motivo y Descripción del traslado</label>
                          <input
                            type="text"
                            className="form-control"
                            onChange={formik.handleChange}
                            name="motivo_descripcion_traslado"
                            autoComplete={false}
                            value={
                              formik.values.motivo_descripcion_traslado || ""
                            }
                          />
                          {formik.errors.motivo_descripcion_traslado ?
                          <>
                            <div className="alert alert-warning mb-4" role="alert">
                              <strong>Atención!</strong> {formik.errors.motivo_descripcion_traslado}
                            </div>
                          </>
                          :
                          null
                          }
                        </div>
                      </div>
                      <div className="col-12">
                        <div className="form-group mb-3">
                          <label>Tipo de Servicio</label>
                          <select
                            className="form-control"
                            onChange={formik.handleChange}
                            name="tipo_servicio"
                            value={formik.values.tipo_servicio || ""}
                          >
                            <option>Seleccionar una opción</option>
                            <option value="TAB">
                              TAB (Traslado Asistencial Basico)
                            </option>
                            <option value="TEC">
                              TEC (Traslado Especializado Covid 19)
                            </option>
                            <option value="TAM">
                              TAM (Traslado Asistencial Medicalizado)
                            </option>
                            <option value="APH">
                              APH (Atención Prehospitalaria)
                            </option>
                            <option value="RDO">RDO (Redondo)</option>
                            <option value="Sencillo">Sencillo</option>
                          </select>
                          {formik.errors.tipo_servicio ?
                          <>
                            <div className="alert alert-warning mb-4" role="alert">
                              <strong>Atención!</strong> {formik.errors.tipo_servicio}
                            </div>
                          </>
                          :
                          null
                          }
                        </div>
                      </div>
                      <div className="col-12">
                        <div className="form-group mb-3">
                          <label>Servicio Prestado</label>
                          <select
                            className="form-control"
                            onChange={formik.handleChange}
                            name="servicio_prestado"
                            value={formik.values.servicio_prestado || ""}
                          >
                            <option>Seleccionar una opción</option>
                            <option value="Enfermedad General">
                              Enfermedad General
                            </option>
                            <option value="Accidente de Tránsito">
                              Accidente de Tránsito
                            </option>
                            <option value="Accidente Laboral">
                              Accidente Laboral
                            </option>
                            <option value="Accidente Estudiantil">
                              Accidente Estudiantil
                            </option>
                            <option value="Servicio Social">
                              Servicio Social
                            </option>
                            <option value="Otro">Otro</option>
                          </select>
                          {formik.errors.servicio_prestado ?
                          <>
                            <div className="alert alert-warning mb-4" role="alert">
                              <strong>Atención!</strong> {formik.errors.servicio_prestado}
                            </div>
                          </>
                          :
                          null
                          }
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* DATOS DEL ACCIDENTE DE TRÁNSITO */}
            <div className="row layout-top-spacing">
              <div className="col-xl-12 col-lg-12 col-md-12 col-12 layout-spacing">
                <div className="statbox widget box box-shadow">
                  <div className="widget-header">
                    <div className="row">
                      <div className="col-xl-12 col-md-12 col-sm-12 col-12">
                        <h4>DATOS DEL ACCIDENTE DE TRÁNSITO</h4>
                      </div>
                    </div>
                  </div>
                  <div className="widget-content widget-content-area">
                    <div className="row">
                      <div className="col-12">
                        <div className="form-group mb-3">
                          <label>Condición del accidentado</label>
                          <select
                            className="form-control"
                            onChange={formik.handleChange}
                            name="condicion_accidentado"
                            value={formik.values.condicion_accidentado || ""}
                          >
                            <option>Seleccionar una opción</option>
                            <option value="Conductor">Conductor</option>
                            <option value="Ocupante">Ocupante</option>
                            <option value="Peaton">Peaton</option>
                            <option value="Ciclista">Ciclista</option>
                            <option value="Otro">Otro</option>
                            <option value="No Aplica">No Aplica</option>
                          </select>
                          {formik.errors.condicion_accidentado ?
                          <>
                            <div className="alert alert-warning mb-4" role="alert">
                              <strong>Atención!</strong> {formik.errors.condicion_accidentado}
                            </div>
                          </>
                          :
                          null
                          }
                        </div>
                      </div>
                      <div className="col-12">
                        <div className="form-group mb-3">
                          <label>Tipo de Vehículo</label>
                          <select
                            className="form-control"
                            onChange={formik.handleChange}
                            name="tipo_vehiculo"
                            value={formik.values.tipo_vehiculo || ""}
                          >
                            <option>Seleccionar una opción</option>
                            <option value="Motocicleta">Motocicleta</option>
                            <option value="Automovil">Automovil</option>
                            <option value="Camioneta">Camioneta</option>
                            <option value="Bus">Bus</option>
                            <option value="Micro Bus">Micro Bus</option>
                            <option value="Vehículo Pesado">
                              Vehículo Pesado
                            </option>
                            <option value="Otro">Otro</option>
                            <option value="No Aplica">No Aplica</option>
                          </select>
                          {formik.errors.tipo_vehiculo ?
                          <>
                            <div className="alert alert-warning mb-4" role="alert">
                              <strong>Atención!</strong> {formik.errors.tipo_vehiculo}
                            </div>
                          </>
                          :
                          null
                          }
                        </div>
                      </div>
                      <div className="col-12">
                        <div className="form-group mb-3">
                          <label>Placa</label>
                          <input
                            type="text"
                            className="form-control"
                            onChange={formik.handleChange}
                            name="placa"
                            autoComplete={false}
                            value={formik.values.placa || ""}
                          />
                          {formik.errors.placa ?
                          <>
                            <div className="alert alert-warning mb-4" role="alert">
                              <strong>Atención!</strong> {formik.errors.placa}
                            </div>
                          </>
                          :
                          null
                          }
                        </div>
                      </div>
                      <div className="col-12">
                        <div className="form-group mb-3">
                          <label>Marca</label>
                          <input
                            type="text"
                            className="form-control"
                            onChange={formik.handleChange}
                            name="marca"
                            autoComplete={false}
                            value={formik.values.marca || ""}
                          />
                          {formik.errors.marca ?
                          <>
                            <div className="alert alert-warning mb-4" role="alert">
                              <strong>Atención!</strong> {formik.errors.marca}
                            </div>
                          </>
                          :
                          null
                          }
                        </div>
                      </div>
                      <div className="col-12">
                        <div className="form-group mb-3">
                          <label>Aseguradora</label>
                          <input
                            type="text"
                            className="form-control"
                            autoComplete={false}
                            onChange={formik.handleChange}
                            name="aseguradora"
                            value={formik.values.aseguradora || ""}
                          />
                          {formik.errors.aseguradora ?
                          <>
                            <div className="alert alert-warning mb-4" role="alert">
                              <strong>Atención!</strong> {formik.errors.aseguradora}
                            </div>
                          </>
                          :
                          null
                          }
                        </div>
                      </div>
                      <div className="col-12">
                        <div className="form-group mb-3">
                          <label>Póliza</label>
                          <input
                            type="text"
                            className="form-control"
                            onChange={formik.handleChange}
                            autoComplete={false}
                            name="poliza"
                            value={formik.values.poliza || ""}
                          />
                          {formik.errors.poliza ?
                          <>
                            <div className="alert alert-warning mb-4" role="alert">
                              <strong>Atención!</strong> {formik.errors.poliza}
                            </div>
                          </>
                          :
                          null
                          }
                        </div>
                      </div>
                      <div className="col-12">
                        <div className="form-group mb-3">
                          <label>AT</label>
                          <input
                            type="text"
                            className="form-control"
                            onChange={formik.handleChange}
                            autoComplete={false}
                            name="at"
                            value={formik.values.at || ""}
                          />
                          {formik.errors.at ?
                          <>
                            <div className="alert alert-warning mb-4" role="alert">
                              <strong>Atención!</strong> {formik.errors.at}
                            </div>
                          </>
                          :
                          null
                          }
                        </div>
                      </div>
                      <div className="col-12">
                        <div className="form-group mb-3">
                          <label>No</label>
                          <input
                            type="text"
                            className="form-control"
                            onChange={formik.handleChange}
                            autoComplete={false}
                            name="no_poliza"
                            value={formik.values.no_poliza || ""}
                          />
                          {formik.errors.no_poliza ?
                          <>
                            <div className="alert alert-warning mb-4" role="alert">
                              <strong>Atención!</strong> {formik.errors.no_poliza}
                            </div>
                          </>
                          :
                          null
                          }
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* EXAMEN FÍSICO EN INICIO DEL TRASLADO */}
            <div className="row layout-top-spacing">
              <div className="col-xl-12 col-lg-12 col-md-12 col-12 layout-spacing">
                <div className="statbox widget box box-shadow">
                  <div className="widget-header">
                    <div className="row">
                      <div className="col-xl-12 col-md-12 col-sm-12 col-12">
                        <h4>EXAMEN FÍSICO EN INICIO DEL TRASLADO</h4>
                      </div>
                    </div>
                  </div>
                  <div className="widget-content widget-content-area">
                    <div className="row">
                      <div className="col-12">
                        <div className="form-group mb-3">
                          <label>Escala de Glassgow</label>
                          <select
                            className="form-control"
                            onChange={formik.handleChange}
                            name="escala_glassgow"
                            value={formik.values.escala_glassgow || ""}
                          >
                            <option>Seleccionar opción</option>
                            <option value="1">1/15</option>
                            <option value="2">2/15</option>
                            <option value="3">3/15</option>
                            <option value="4">4/15</option>
                            <option value="5">5/15</option>
                            <option value="6">6/15</option>
                            <option value="7">7/15</option>
                            <option value="8">8/15</option>
                            <option value="9">9/15</option>
                            <option value="10">10/15</option>
                            <option value="11">11/15</option>
                            <option value="12">12/15</option>
                            <option value="13">13/15</option>
                            <option value="14">14/15</option>
                            <option value="15">15/15</option>
                            <option value="No Aplica">No Aplica</option>
                          </select>
                          {formik.errors.escala_glassgow ?
                          <>
                            <div className="alert alert-warning mb-4" role="alert">
                              <strong>Atención!</strong> {formik.errors.escala_glassgow}
                            </div>
                          </>
                          :
                          null
                          }
                        </div>
                      </div>
                      <div className="col-12">
                        <div className="form-group mb-3">
                          <label>Frecuencia Respiratoria /MIN</label>
                          <input
                            type="number"
                            className="form-control"
                            onChange={formik.handleChange}
                            autoComplete={false}
                            name="frecuencia_respiratoria"
                            value={formik.values.frecuencia_respiratoria || ""}
                          />
                          {formik.errors.frecuencia_respiratoria ?
                          <>
                            <div className="alert alert-warning mb-4" role="alert">
                              <strong>Atención!</strong> {formik.errors.frecuencia_respiratoria}
                            </div>
                          </>
                          :
                          null
                          }
                        </div>
                      </div>
                      <div className="col-12">
                        <div className="form-group mb-3">
                          <label>Frecuencia Cardiaca /MIN</label>
                          <input
                            type="number"
                            className="form-control"
                            onChange={formik.handleChange}
                            autoComplete={false}
                            name="frecuencia_cardiaca"
                            value={formik.values.frecuencia_cardiaca || ""}
                          />
                          {formik.errors.frecuencia_cardiaca ?
                          <>
                            <div className="alert alert-warning mb-4" role="alert">
                              <strong>Atención!</strong> {formik.errors.frecuencia_cardiaca}
                            </div>
                          </>
                          :
                          null
                          }
                        </div>
                      </div>
                      <div className="col-12">
                        <div className="form-group mb-3">
                          <label>Saturación de Oxígeno /SPO2</label>
                          <input
                            type="number"
                            className="form-control"
                            onChange={formik.handleChange}
                            autoComplete={false}
                            name="saturacion"
                            value={formik.values.saturacion || ""}
                          />
                          {formik.errors.saturacion ?
                          <>
                            <div className="alert alert-warning mb-4" role="alert">
                              <strong>Atención!</strong> {formik.errors.saturacion}
                            </div>
                          </>
                          :
                          null
                          }
                        </div>
                      </div>
                      <div className="col-12">
                        <div className="form-group mb-3">
                          <label>Temperatura /Grados</label>
                          <input
                            type="number"
                            className="form-control"
                            onChange={formik.handleChange}
                            autoComplete={false}
                            name="temperatura"
                            value={formik.values.temperatura || ""}
                          />
                          {formik.errors.temperatura ?
                          <>
                            <div className="alert alert-warning mb-4" role="alert">
                              <strong>Atención!</strong> {formik.errors.temperatura}
                            </div>
                          </>
                          :
                          null
                          }
                        </div>
                      </div>
                      <div className="col-12">
                        <div className="form-group mb-3">
                          <label>Glucometría </label>
                          <input
                            type="number"
                            className="form-control"
                            onChange={formik.handleChange}
                            autoComplete={false}
                            name="glucometria"
                            value={formik.values.glucometria || ""}
                          />
                          
                        </div>
                      </div>
                      <div className="col-12">
                        <div className="form-group mb-3">
                          <label>Oxígeno </label>
                          <input
                            type="number"
                            className="form-control"
                            onChange={formik.handleChange}
                            autoComplete={false}
                            name="oxigeno"
                            value={formik.values.oxigeno || ""}
                          />
                          {formik.errors.oxigeno ?
                          <>
                            <div className="alert alert-warning mb-4" role="alert">
                              <strong>Atención!</strong> {formik.errors.oxigeno}
                            </div>
                          </>
                          :
                          null
                          }
                        </div>
                      </div>
                      <div className="col-12">
                        <div className="form-group mb-3">
                          <label>Presión arterial sistólica </label>
                          <input
                            type="number"
                            className="form-control"
                            onChange={formik.handleChange}
                            autoComplete={false}
                            name="presion_arterial_sistolica"
                            value={
                              formik.values.presion_arterial_sistolica || ""
                            }
                          />
                          {formik.errors.presion_arterial_sistolica ?
                          <>
                            <div className="alert alert-warning mb-4" role="alert">
                              <strong>Atención!</strong> {formik.errors.presion_arterial_sistolica}
                            </div>
                          </>
                          :
                          null
                          }
                        </div>
                      </div>
                      <div className="col-12">
                        <div className="form-group mb-3">
                          <label>Presión arterial diastólica </label>
                          <input
                            type="number"
                            className="form-control"
                            onChange={formik.handleChange}
                            autoComplete={false}
                            name="presion_arterial_diastolica"
                            value={
                              formik.values.presion_arterial_diastolica || ""
                            }
                          />
                          {formik.errors.presion_arterial_diastolica ?
                          <>
                            <div className="alert alert-warning mb-4" role="alert">
                              <strong>Atención!</strong> {formik.errors.presion_arterial_diastolica}
                            </div>
                          </>
                          :
                          null
                          }
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* ANÁLISIS Y OBSERVACIONES DURANTE EL TRASLADO */}
            <div className="row layout-top-spacing">
              <div className="col-xl-12 col-lg-12 col-md-12 col-12 layout-spacing">
                <div className="statbox widget box box-shadow">
                  <div className="widget-header">
                    <div className="row">
                      <div className="col-xl-12 col-md-12 col-sm-12 col-12">
                        <h4>ANÁLISIS Y OBSERVACIONES DURANTE EL TRASLADO</h4>
                      </div>
                    </div>
                  </div>
                  <div className="widget-content widget-content-area">
                    <div className="row">
                      <div className="col-12">
                        <div className="form-group mb-3">
                          <textarea
                            className="form-control"
                            onChange={formik.handleChange}
                            autoComplete={false}
                            name="analisis_observaciones_traslado"
                            defaultValue={
                              formik.values.analisis_observaciones_traslado ||
                              ""
                            }
                          ></textarea>
                          {formik.errors.analisis_observaciones_traslado ?
                          <>
                            <div className="alert alert-warning mb-4" role="alert">
                              <strong>Atención!</strong> {formik.errors.analisis_observaciones_traslado}
                            </div>
                          </>
                          :
                          null
                          }
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* TRASLADO FINAL  IPS/HOSPITAL/CENTRO MÉDICO/ */}
            <div className="row layout-top-spacing">
              <div className="col-xl-12 col-lg-12 col-md-12 col-12 layout-spacing">
                <div className="statbox widget box box-shadow">
                  <div className="widget-header">
                    <div className="row">
                      <div className="col-xl-12 col-md-12 col-sm-12 col-12">
                        <h4>TRASLADO FINAL IPS/HOSPITAL/CENTRO MÉDICO/</h4>
                      </div>
                    </div>
                  </div>
                  <div className="widget-content widget-content-area">
                    <div className="row">
                      <div className="col-12">
                        <div className="form-group mb-3">
                          <label>Nombre Institucion </label>
                          <input
                            type="text"
                            className="form-control"
                            onChange={formik.handleChange}
                            autoComplete={false}
                            name="nombre_institucion_traslado_final"
                            value={
                              formik.values.nombre_institucion_traslado_final ||
                              ""
                            }
                          />
                          {formik.errors.nombre_institucion_traslado_final ?
                          <>
                            <div className="alert alert-warning mb-4" role="alert">
                              <strong>Atención!</strong> {formik.errors.nombre_institucion_traslado_final}
                            </div>
                          </>
                          :
                          null
                          }
                        </div>
                      </div>

                      <div className="col-12">
                        <div className="form-group mb-3">
                          <label>Servicio </label>
                          <input
                            type="text"
                            className="form-control"
                            onChange={formik.handleChange}
                            autoComplete={false}
                            name="servicio_traslado_final"
                            value={formik.values.servicio_traslado_final || ""}
                          />
                          {formik.errors.servicio_traslado_final ?
                          <>
                            <div className="alert alert-warning mb-4" role="alert">
                              <strong>Atención!</strong> {formik.errors.servicio_traslado_final}
                            </div>
                          </>
                          :
                          null
                          }
                        </div>
                      </div>

                      <div className="col-12">
                        <div className="form-group mb-3">
                          <label>Habitación/Cama </label>
                          <input
                            type="text"
                            className="form-control"
                            onChange={formik.handleChange}
                            autoComplete={false}
                            name="habilitacion_cama_traslado_final"
                            value={
                              formik.values.habilitacion_cama_traslado_final ||
                              ""
                            }
                          />
                          {formik.errors.habilitacion_cama_traslado_final ?
                          <>
                            <div className="alert alert-warning mb-4" role="alert">
                              <strong>Atención!</strong> {formik.errors.habilitacion_cama_traslado_final}
                            </div>
                          </>
                          :
                          null
                          }
                        </div>
                      </div>

                      <div className="col-12">
                        <div className="form-group mb-3">
                          <label>Ciudad </label>
                          <input
                            type="text"
                            className="form-control"
                            onChange={formik.handleChange}
                            autoComplete={false}
                            name="ciudad_traslado_final"
                            value={formik.values.ciudad_traslado_final || ""}
                          />
                          {formik.errors.ciudad_traslado_final ?
                          <>
                            <div className="alert alert-warning mb-4" role="alert">
                              <strong>Atención!</strong> {formik.errors.ciudad_traslado_final}
                            </div>
                          </>
                          :
                          null
                          }
                        </div>
                      </div>

                      <div className="col-12">
                        <div className="form-group mb-3">
                          <label>Departamento </label>
                          <input
                            type="text"
                            className="form-control"
                            onChange={formik.handleChange}
                            autoComplete={false}
                            name="departamento_traslado_final"
                            value={
                              formik.values.departamento_traslado_final || ""
                            }
                          />
                          {formik.errors.departamento_traslado_final ?
                          <>
                            <div className="alert alert-warning mb-4" role="alert">
                              <strong>Atención!</strong> {formik.errors.departamento_traslado_final}
                            </div>
                          </>
                          :
                          null
                          }
                        </div>
                      </div>

                      <div className="col-12">
                        <div className="form-group mb-3">
                          <label>Fecha </label>
                          <input
                            type="date"
                            className="form-control"
                            onChange={formik.handleChange}
                            autoComplete={false}
                            name="fecha_traslado_final"
                            value={formik.values.fecha_traslado_final || ""}
                          />
                          {formik.errors.fecha_traslado_final ?
                          <>
                            <div className="alert alert-warning mb-4" role="alert">
                              <strong>Atención!</strong> {formik.errors.fecha_traslado_final}
                            </div>
                          </>
                          :
                          null
                          }
                        </div>
                      </div>

                      <div className="col-12">
                        <div className="form-group mb-3">
                          <label>Hora </label>
                          <input
                            type="time"
                            className="form-control"
                            onChange={formik.handleChange}
                            name="hora_traslado_final"
                            value={formik.values.hora_traslado_final || ""}
                          />
                          {formik.errors.hora_traslado_final ?
                          <>
                            <div className="alert alert-warning mb-4" role="alert">
                              <strong>Atención!</strong> {formik.errors.hora_traslado_final}
                            </div>
                          </>
                          :
                          null
                          }
                        </div>
                      </div>
                      

                      <div className="col-12">
                        <div className="form-group mb-3">
                          <textarea className="form-control"></textarea>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row layout-top-spacing">
              <div className="col-xl-12 col-lg-12 col-md-12 col-12 layout-spacing">
                <div className="statbox widget box box-shadow">
                  <div className="widget-header">
                    <div className="row">
                      <div className="col-xl-12 col-md-12 col-sm-12 col-12">
                        <h4>FIRMA DEL MÉDICO</h4>
                      </div>
                    </div>
                  </div>
                  <div className="widget-content widget-content-area">
                    <div className="row">
                    <div className="col-12">
                        <div className="form-group mb-3">
                          <CanvasDraw
                            ref={DrawMedico}
                            brushColor={"black"}
                            brushRadius={1}
                            lazyRadius={1}
                            canvasWidth={600}
                            canvasHeight={200}
                          />
                          <br/>
                          <button type="button" className="btn btn-danger" onClick={ClearDrawMedico}>Limpiar</button>
                          <button type="button" className="btn btn-success" onClick={SaveDrawMedico}>Guardar firma</button>
                        </div>
                      </div>

                      <div className="col-12">
                        <div className="form-group mb-3">
                          <label>Documento o Registro médico</label>
                          <input
                            type="text"
                            className="form-control"
                            onChange={formik.handleChange}
                            name="registro_medico"
                            value={formik.values.registro_medico || ""}
                          />
                          {formik.errors.registro_medico ?
                          <>
                            <div className="alert alert-warning mb-4" role="alert">
                              <strong>Atención!</strong> {formik.errors.registro_medico}
                            </div>
                          </>
                          :
                          null
                          }
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row layout-top-spacing">
              <div className="col-xl-12 col-lg-12 col-md-12 col-12 layout-spacing">
                <div className="statbox widget box box-shadow">
                  <div className="widget-header">
                    <div className="row">
                      <div className="col-xl-12 col-md-12 col-sm-12 col-12">
                        <h4>CONSENTIMIENTO INFORMADO</h4>
                      </div>
                    </div>
                  </div>
                  <div className="widget-content widget-content-area">
                    <div className="row">
                    <div className="col-12">
                        <div className="form-group mb-3">
                          <p>
                          He comprendido lo que se me ha explicado; doy mi consentimiento y autorizo al equipo correspondiente para llevar a cabo el traslado y efectuar los procedimientos terapéuticos que se consideren indicados. Sé también que puedo retractarme de la firma de este consentimiento previo al traslado, haciendo mención expresa de ello y firmando al lado de la firma del consentimiento
                          </p>
                        </div>
                      </div>
                      <div className="col-12">
                        <div className="form-group mb-3">
                          <label>Autorizo Traslado</label>
                          <select
                            className="form-control"
                            onChange={formik.handleChange}
                            name="autoriza_traslado"
                            value={formik.values.autoriza_traslado || ""}
                          >
                            <option>Seleccionar Opción</option>
                            <option value="si">Si</option>
                            <option value="no">No</option>
                            <option value="noaplica">No Aplica</option>
                          </select>
                          {formik.errors.autoriza_traslado ?
                          <>
                            <div className="alert alert-warning mb-4" role="alert">
                              <strong>Atención!</strong> {formik.errors.autoriza_traslado}
                            </div>
                          </>
                          :
                          null
                          }
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row layout-top-spacing">
              <div className="col-xl-12 col-lg-12 col-md-12 col-12 layout-spacing">
                <div className="statbox widget box box-shadow">
                  <div className="widget-header">
                    <div className="row">
                      <div className="col-xl-12 col-md-12 col-sm-12 col-12">
                        <h4>FIRMA DEL PACIENTE / ACOMPAÑANTE</h4>
                      </div>
                    </div>
                  </div>
                  <div className="widget-content widget-content-area">
                    <div className="row">
                    <div className="col-12">
                        <div className="form-group mb-3">
                          <CanvasDraw
                            ref={DrawPaciente}
                            brushColor={"black"}
                            brushRadius={1}
                            lazyRadius={1}
                            canvasWidth={600}
                            canvasHeight={200}
                          />
                          <br/>
                          <button type="button" className="btn btn-danger" onClick={ClearDrawPaciente}>Limpiar</button>
                          <button type="button" className="btn btn-success" onClick={SaveDrawPaciente}>Guardar firma</button>
                        </div>
                      </div>

                      <div className="col-12">
                        <div className="form-group mb-3">
                          <label>Documento Paciente / Acompañante</label>
                          <input
                            type="number"
                            className="form-control"
                            onChange={formik.handleChange}
                            name="documento_paciente"
                            value={formik.values.documento_paciente || ""}
                          />
                          {formik.errors.documento_paciente ?
                          <>
                            <div className="alert alert-warning mb-4" role="alert">
                              <strong>Atención!</strong> {formik.errors.documento_paciente}
                            </div>
                          </>
                          :
                          null
                          }
                          </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row layout-top-spacing">
              <div className="col-xl-12 col-lg-12 col-md-12 col-12 layout-spacing">
                <div className="statbox widget box box-shadow">
                  <div className="widget-header">
                    <div className="row">
                      <div className="col-xl-12 col-md-12 col-sm-12 col-12">
                        <h4>FIRMA DEL TRIPULANTE DE LA AMBULANCIA</h4>
                      </div>
                    </div>
                  </div>
                  <div className="widget-content widget-content-area">
                    <div className="row">
                    <div className="col-12">
                        <div className="form-group mb-3">
                          <CanvasDraw
                            ref={DrawTripulante}
                            brushColor={"black"}
                            brushRadius={1}
                            lazyRadius={1}
                            canvasWidth={600}
                            canvasHeight={200}
                          />
                          <br/>
                          <button type="button" className="btn btn-danger" onClick={ClearDrawTripulante}>Limpiar</button>
                          <button type="button" className="btn btn-success" onClick={SaveDrawTripulante}>Guardar firma</button>
                        </div>
                      </div>

                      <div className="col-12">
                        <div className="form-group mb-3">
                          <label>Documento Tripulante Ambulancia</label>
                          <input
                            type="number"
                            className="form-control"
                            onChange={formik.handleChange}
                            name="documento_tripulante_ambulancia"
                            value={formik.values.documento_tripulante_ambulancia || ""}
                          />
                          {formik.errors.documento_tripulante_ambulancia ?
                          <>
                            <div className="alert alert-warning mb-4" role="alert">
                              <strong>Atención!</strong> {formik.errors.documento_tripulante_ambulancia}
                            </div>
                          </>
                          :
                          null
                          }
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row layout-top-spacing">
              <div className="col-xl-12 col-lg-12 col-md-12 col-12 layout-spacing">
                <div className="statbox widget box box-shadow">
                  <div className="widget-header">
                    <div className="row">
                      <div className="col-xl-12 col-md-12 col-sm-12 col-12">
                        <h4>FIRMA DEL CONDUCTOR DE LA AMBULANCIA</h4>
                      </div>
                    </div>
                  </div>
                  <div className="widget-content widget-content-area">
                    <div className="row">
                    <div className="col-12">
                        <div className="form-group mb-3">
                          <CanvasDraw
                            ref={DrawConductor}
                            brushColor={"black"}
                            brushRadius={1}
                            lazyRadius={1}
                            canvasWidth={600}
                            canvasHeight={200}
                          />
                          <br/>
                          <button type="button" className="btn btn-danger" onClick={ClearDrawConductor}>Limpiar</button>
                          <button type="button" className="btn btn-success" onClick={SaveDrawConductor}>Guardar firma</button>
                          
                        </div>
                      </div>

                      <div className="col-12">
                        <div className="form-group mb-3">
                          <label>Documento </label>
                          <input
                            type="number"
                            className="form-control"
                            onChange={formik.handleChange}
                            name="documento_conductor_ambulancia"
                            value={formik.values.documento_conductor_ambulancia || ""}
                          />
                          {formik.errors.documento_conductor_ambulancia ?
                          <>
                            <div className="alert alert-warning mb-4" role="alert">
                              <strong>Atención!</strong> {formik.errors.documento_conductor_ambulancia}
                            </div>
                          </>
                          :
                          null
                          }
                          
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* FIN DEL FORMULARIO */}
            {step && (
              <>
              <button type="submit" className="btn btn-success">
                {!loading ? 'Guardar' : 'Guardando...'}
              </button>
              
              </>
            )}
          </>
        )}
      </form>
    </>
  );
}

export default NuevaHistoria;
