import React from "react";
import axios from "axios";
import Config from "../../../Config";
import { useFormik } from "formik";
import { ToastContainer, toast } from "react-toastify";
import CanvasDraw from "react-canvas-draw";
import { useParams, useHistory } from 'react-router-dom';
import domtoimage from 'dom-to-image';
import { jsPDF } from "jspdf";
import html2pdf from "html2pdf.js";
import { Link } from 'react-router-dom';
function VerHistoria() {
  //Hooks
  const [loading, setLoading] = React.useState(false);
  const [dataPaciente, setDataPaciente] = React.useState(null);
  //Step 0 -> Buscar el paciente
  //Step 1 -> Crear el paciente si no existe o Mostrar los datos del paciente
  const urlParams = useParams();
  const DrawMedico = React.useRef();
  const DrawPaciente = React.useRef();
  const DrawConductor = React.useRef();
  const DrawTripulante = React.useRef();

  React.useEffect(()=>{
    if(urlParams.IdHistoria){
        BuscarHistoria();
    }
  },[])

  
  
 const Imprimir = () => {
   

    const input = document.getElementById('printarea');
    html2pdf(input);
    // const pdf = new jsPDF();
    // if (pdf) {
    //   domtoimage.toPng(input)
    //     .then(imgData => {
    //       var img = new Image();
    //       img.src = imgData;
    //       console.log(imgData);
    //       pdf.addImage(img, 'JPG', 0, 0);
    //       pdf.save('download.pdf');
    //     });
    // }

 }

  // Firma Medico
  const SaveDrawMedico = (e) => {
    e.preventDefault();
    const data = DrawMedico.current.getSaveData();
    localStorage.setItem('SaveDrawMedico', data);
    formik.setFieldValue('firma_medico' , data);
    toast.success('Firma medico guardada exitosamente');
  }

  const ClearDrawMedico = (e) => {
    e.preventDefault();
    DrawMedico.current.clear();
    SaveDrawMedico(e);
    //formik.setFieldValue('firma_medico' , null);
  }

  //Firma Paciente
  const SaveDrawPaciente = (e) => {
    e.preventDefault();
    const data = DrawPaciente.current.getSaveData();
    //localStorage.setItem('SaveDrawPaciente', data);
    formik.setFieldValue('firma_paciente' , data);
    toast.success('Firma paciente guardada exitosamente');
  }

  const ClearDrawPaciente = (e) => {
    e.preventDefault();
    DrawPaciente.current.clear();
    SaveDrawPaciente(e);
    //formik.setFieldValue('firma_paciente' , null);
  }

  //Firma Conductor
  const SaveDrawConductor = (e) => {
    e.preventDefault();
    const data = DrawConductor.current.getSaveData();
    //localStorage.setItem('SaveDrawConductor', data);
    formik.setFieldValue('conductor_ambulancia' , data);
    toast.success('Firma conductor guardada exitosamente');
  }

  const ClearDrawConductor = (e) => {
    e.preventDefault();
    DrawConductor.current.clear();
    SaveDrawConductor(e);
    //formik.setFieldValue('conductor_ambulancia' , null);
  }

  //Firma Tripulante
  const SaveDrawTripulante = (e) => {
    e.preventDefault();
    const data = DrawTripulante.current.getSaveData();
    //localStorage.setItem('SaveDrawTripulante', data);
    formik.setFieldValue('tripulante_ambulancia' , data);
    toast.success('Firma tripulante guardada exitosamente');
  }
  const ClearDrawTripulante = (e) => {
    e.preventDefault();
    DrawTripulante.current.clear();
    SaveDrawTripulante(e);
    //formik.setFieldValue('tripulante_ambulancia' , '' , false);
  }

  //Formik
  const formik = useFormik({
    initialValues: {},
    async onSubmit(values) {
        
        let paramsToApi = {
          origen_del_servicio: formik.values.origen_del_servicio,
          hora_llamada: formik.values.hora_llamada,
          fecha: formik.values.fecha,
          hora_llegada: formik.values.hora_llegada,
          zona: formik.values.zona,
          no_movil: formik.values.no_movil,
          placa_movil: formik.values.placa_movil,
          lugar_origen_ocurrencia: formik.values.lugar_origen_ocurrencia,
          motivo_descripcion_traslado: formik.values.motivo_descripcion_traslado,
          tipo_servicio: formik.values.tipo_servicio,
          ciudad_hc: formik.values.ciudad_hc,
          departamento_hc: formik.values.departamento_hc,
          servicio_prestado: formik.values.servicio_prestado,
          condicion_accidentado: formik.values.condicion_accidentado,
          tipo_vehiculo: formik.values.tipo_vehiculo,
          placa: formik.values.placa,
          marca: formik.values.marca,
          aseguradora: formik.values.aseguradora,
          poliza: formik.values.poliza,
          at: formik.values.at,
          no_poliza: formik.values.no_poliza,
          escala_glassgow: formik.values.escala_glassgow,
          frecuencia_respiratoria: formik.values.frecuencia_respiratoria,
          frecuencia_cardiaca: formik.values.frecuencia_cardiaca,
          saturacion: formik.values.saturacion,
          temperatura: formik.values.temperatura,
          glucometria: formik.values.glucometria,
          oxigeno: formik.values.oxigeno,
          presion_arterial_diastolica: formik.values.presion_arterial_diastolica,
          presion_arterial_sistolica: formik.values.presion_arterial_sistolica,
          analisis_observaciones_traslado: formik.values.analisis_observaciones_traslado,
          nombre_institucion_traslado_final: formik.values.nombre_institucion_traslado_final,
          servicio_traslado_final: formik.values.servicio_traslado_final,
          habilitacion_cama_traslado_final:formik.values.habilitacion_cama_traslado_final,
          ciudad_traslado_final: formik.values.ciudad_traslado_final,
          departamento_traslado_final:formik.values.departamento_traslado_final,
          hora_traslado_final: formik.values.hora_traslado_final,
          fecha_traslado_final: formik.values.fecha_traslado_final,
          autoriza_traslado: formik.values.autoriza_traslado,
          //firma_medico: formik.values.firma_medico,
          firma_medico: formik.values.firma_medico,
          registro_medico: formik.values.registro_medico,
          firma_paciente: formik.values.firma_paciente,
          documento_paciente: formik.values.documento_paciente,
          tripulante_ambulancia: formik.values.tripulante_ambulancia,
          documento_tripulante_ambulancia:formik.values.documento_tripulante_ambulancia,
          conductor_ambulancia: formik.values.conductor_ambulancia,
          documento_conductor_ambulancia:formik.values.documento_conductor_ambulancia,
          };
          setLoading(true);
        await axios
          .put(Config.url + "historia-clinicas/"+urlParams.IdHistoria, paramsToApi, { headers: { 'Authorization': 'Bearer ' + localStorage.getItem('token') } } )
          .then((response) => {
            console.log(response);
            toast.success('Cambios guardados exitosamente');
            // localStorage.removeItem('SaveDrawMedico');
            // localStorage.removeItem('SaveDrawPaciente');
            // localStorage.removeItem('SaveDrawTripulante');
            // localStorage.removeItem('SaveDrawConductor');
            setLoading(false);
        })
          .catch((error) => {
            console.error(error);
            setLoading(false);
          });
      

      console.log(values);
    },
  });

  //Functions
  async function BuscarHistoria() {
    setLoading(true);
    const headers = {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("siteToken"),
      },
    };
    await axios
      .get(Config.url + 'historia-clinicas/'+urlParams.IdHistoria, { headers: { 'Authorization': 'Bearer ' + localStorage.getItem('token') } } ).then((response) => {
        setLoading(false);
        console.log(response);
        setDataPaciente(response.data.paciente);
        let data = response.data;
        console.log(data);
        //return;
        
          const fields = [
            "origen_del_servicio",
            "fecha",
            "zona",
            "no_movil",
            "placa_movil",
            "lugar_origen_ocurrencia",
            "motivo_descripcion_traslado",
            "tipo_servicio",
            "ciudad_hc",
            "departamento_hc",
            "servicio_prestado",
            "condicion_accidentado",
            "tipo_vehiculo",
            "placa",
            "marca",
            "aseguradora",
            "poliza",
            "at",
            "no_poliza",
            "escala_glassgow",
            "frecuencia_respiratoria",
            "frecuencia_cardiaca",
            "saturacion",
            "temperatura",
            "glucometria",
            "oxigeno",
            "presion_arterial_diastolica",
            "presion_arterial_sistolica",
            "analisis_observaciones_traslado",
            "nombre_institucion_traslado_final",
            "servicio_traslado_final",
            "habilitacion_cama_traslado_final",
            "ciudad_traslado_final",
            "departamento_traslado_final",
            "hora_traslado_final",
            "fecha_traslado_final",
            "autoriza_traslado",
            "firma_medico",
            "registro_medico",
            "firma_paciente",
            "documento_paciente",
            "tripulante_ambulancia",
            "documento_tripulante_ambulancia",
            "conductor_ambulancia",
            "documento_conductor_ambulancia",
            "hora_llegada",
            "hora_llamada",
            "hc_cerrada"
          ];
          fields.forEach((field) =>
            formik.setFieldValue(field, data[field], false)
          );
          
        
      })
      .catch((error) => {
        setLoading(false);
        console.error(error);
      });
  }

  return (
    <>
      <ToastContainer />
      
        {/* INICIO DEL FORMULARIO */}

        {/* DATOS DEL PACIENTE */}
        {dataPaciente
        ?
        <>
        <form onSubmit={formik.handleSubmit} id="printarea">
          <div >

          
<div className="row layout-top-spacing">
          <div className="col-xl-12 col-lg-12 col-md-12 col-12 layout-spacing">
            <div className="statbox widget box box-shadow">
              <div className="widget-header">
                <div className="row">
                  <div className="col-xl-12 col-md-12 col-sm-12 col-12">
                    <h4>DATOS DEL PACIENTE</h4>
                  </div>
                </div>
              </div>
              <div className="widget-content widget-content-area">
                <div className="row">
                  <>
                      <div className="col-12">
                      <div className="form-group mb-3">
                          <label>Tipo Documento</label>
                          <input
                            type="text"
                            className="form-control"
                            name="tipo_documento"
                            value={dataPaciente.tipo_documento}
                            readOnly={true}
                          />
                        </div>
                    </div>

                      <div className="col-12">
                      <div className="form-group mb-3">
                          <label>Numero Documento</label>
                          <input
                            type="text"
                            className="form-control"
                            name="numero_documento"
                            value={dataPaciente.numero_documento}
                            readOnly={true}
                          />
                        </div>
                    </div>

                      <div className="col-12">
                        <div className="form-group mb-3">
                          <label>Nombre Completo</label>
                          <input
                            type="text"
                            className="form-control"
                            name="nombre_completo"
                            value={dataPaciente.nombre_completo}
                            readOnly={true}
                          />
                        </div>
                      </div>

                      <div className="col-12">
                        <div className="form-group mb-3">
                          <label>Fecha de Nacimiento</label>
                          <input
                            type="date"
                            className="form-control"
                            name="fecha_nacimiento"
                            value={dataPaciente.fecha_nacimiento}
                            readOnly={true}
                          />
                        </div>
                      </div>

                      <div className="col-12">
                        <div className="form-group mb-3">
                          <label>Edad</label>
                          <input
                            type="text"
                            className="form-control"
                            name="edad"
                            value={dataPaciente.edad}
                            readOnly={true}
                          />
                        </div>
                      </div>

                      <div className="col-12">
                      <div className="form-group mb-3">
                          <label>Sexo</label>
                          <input
                            type="text"
                            className="form-control"
                            name="sexo"
                            value={dataPaciente.sexo}
                            readOnly={true}
                          />
                        </div>
                      </div>

                      <div className="col-12">
                        <div className="form-group mb-3">
                          <label>EPS</label>
                          <input
                            type="text"
                            className="form-control"
                            name="eps"
                            value={dataPaciente.eps}
                            readOnly={true}
                          />
                        </div>
                      </div>

                      <div className="col-12">
                      <div className="form-group mb-3">
                          <label>Estado Civil</label>
                          <input
                            type="text"
                            className="form-control"
                            name="estado_civil"
                            value={dataPaciente.estado_civil}
                            readOnly={true}
                          />
                        </div>
                      </div>

                      <div className="col-12">
                        <div className="form-group mb-3">
                          <label>Dirección</label>
                          <input
                            type="text"
                            className="form-control"
                            name="direccion"
                            value={dataPaciente.direccion}
                            readOnly={true}
                          />
                        </div>
                      </div>

                      <div className="col-12">
                        <div className="form-group mb-3">
                          <label>Ocupación</label>
                          <input
                            type="text"
                            className="form-control"
                            name="ocupacion"
                            value={dataPaciente.ocupacion}
                            readOnly={true}
                          />
                        </div>
                      </div>

                      <div className="col-12">
                        <div className="form-group mb-3">
                          <label>Teléfono</label>
                          <input
                            type="number"
                            className="form-control"
                            name="telefono"
                            value={dataPaciente.telefono}
                            readOnly={true}
                          />
                        </div>
                      </div>

                      <div className="col-12">
                        <div className="form-group mb-3">
                          <label>Ciudad</label>
                          <input
                            type="text"
                            className="form-control"
                            name="ciudad_hc"
                            value={dataPaciente.ciudad}
                            readOnly={true}
                          />
                        </div>
                      </div>

                      <div className="col-12">
                        <div className="form-group mb-3">
                          <label>Departamento</label>
                          <input
                            type="text"
                            className="form-control"
                            name="departamento"
                            value={dataPaciente.departamento}
                            readOnly={true}
                          />
                        </div>
                      </div>

                      <div className="col-12">
                        <div className="form-group mb-3">
                          <label>Nombre Acompañante</label>
                          <input
                            type="text"
                            className="form-control"
                            name="nombre_acompa"
                            value={dataPaciente.nombre_acompa}
                            readOnly={true}
                          />
                        </div>
                      </div>

                      <div className="col-12">
                        <div className="form-group mb-3">
                          <label>Parentezco</label>
                          <input
                            type="text"
                            className="form-control"
                            name="parentesco"
                            value={dataPaciente.parentesco}
                            readOnly={true}
                          />
                        </div>
                      </div>

                      <div className="col-12">
                        <div className="form-group mb-3">
                          <label>Diagnóstico de Enfermedades</label>
                          <input
                            type="text"
                            className="form-control"
                            name="diagnostico_enfermedades"
                            value={dataPaciente.diagnostico_enfermedades}
                            readOnly={true}
                          />
                        </div>
                      </div>

                      <h3>Antecedentes Patológicos</h3>

                      <div className="col-12">
                        <div className="form-group mb-3">
                          <label>Alergias</label>
                          <input
                            type="text"
                            className="form-control"
                            name="antecedentespatologicos_alergias"
                            value={dataPaciente.antecedentespatologicos_alergias}
                            readOnly={true}
                          />
                        </div>
                      </div>

                      <div className="col-12">
                        <div className="form-group mb-3">
                          <label>Familiares</label>
                          <input
                            type="text"
                            className="form-control"
                            name="antecedentespatologicos_familiares"
                            value={dataPaciente.antecedentespatologicos_familiares}
                            readOnly={true}
                          />
                        </div>
                      </div>

                      <div className="col-12">
                        <div className="form-group mb-3">
                          <label>Cirugias</label>
                          <input
                            type="text"
                            className="form-control"
                            name="antecedentespatologicos_cirugias"
                            value={dataPaciente.antecedentespatologicos_cirugias}
                            readOnly={true}
                          />
                        </div>
                      </div>

                      <div className="col-12">
                        <div className="form-group mb-3">
                          <label>Medicamentos</label>
                          <input
                            type="text"
                            className="form-control"
                            name="antecedentespatologicos_medicamentos"
                            value={dataPaciente.antecedentespatologicos_medicamentos}
                            readOnly={true}
                          />
                        </div>
                      </div>
                    </>
                  
                </div>
                </div>
            </div>
          </div>
        </div>
        
          <>
            {/* DATOS DEL TRASLADO */}
            <div className="row layout-top-spacing">
              <div className="col-xl-12 col-lg-12 col-md-12 col-12 layout-spacing">
                <div className="statbox widget box box-shadow">
                  <div className="widget-header">
                    <div className="row">
                      <div className="col-xl-12 col-md-12 col-sm-12 col-12">
                        <h4>DATOS DEL TRASLADO</h4>
                      </div>
                    </div>
                  </div>
                  <div className="widget-content widget-content-area">
                    <div className="row">
                    <div className="col-12">
                        <div className="form-group mb-3">
                          <label>Origen del servicio</label>
                          <select
                            className="form-control"
                            onChange={formik.handleChange}
                            name="origen_del_servicio"

                            value={formik.values.origen_del_servicio || ""}
                          >
                            <option>Seleccione una opción</option>
                            <option>SOAT</option>
                            <option>CCQ</option>
                            <option>ARL</option>
                            <option>Particular</option>
                            <option>Convenio</option>  
                            <option>Fundaciones</option>  
                            <option>Otro</option>  
                          </select>
                          {formik.errors.origen_del_servicio ?
                          <>
                            <div className="alert alert-warning mb-4" role="alert">
                              <strong>Atención!</strong> {formik.errors.origen_del_servicio}
                            </div>
                          </>
                          :
                          null
                          }
                        </div>
                      </div>
                      <div className="col-12">
                        <div className="form-group mb-3">
                          <label>Ciudad</label>
                          <input
                            type="text"
                            className="form-control"
                            onChange={formik.handleChange}
                            name="ciudad_hc"
                            value={formik.values.ciudad_hc || ""}
                          />
                          {formik.errors.ciudad_hc &&
                            formik.touched.ciudad_hc &&
                            formik.errors.ciudad_hc}
                        </div>
                      </div>
                      <div className="col-12">
                        <div className="form-group mb-3">
                          <label>Departamento</label>
                          <input
                            type="text"
                            className="form-control"
                            onChange={formik.handleChange}
                            name="departamento_hc"
                            value={formik.values.departamento_hc || ""}
                          />
                          {formik.errors.departamento_hc &&
                            formik.touched.departamento_hc &&
                            formik.errors.departamento_hc}
                        </div>
                      </div>
                      <div className="col-12">
                        <div className="form-group mb-3">
                          <label>Hora Llamado</label>
                          <input
                            type="time"
                            className="form-control"
                            onChange={formik.handleChange}
                            name="hora_llamada"
                            value={formik.values.hora_llamada || ""}
                          />
                          {formik.errors.hora_llamada &&
                            formik.touched.hora_llamada &&
                            formik.errors.hora_llamada}
                        </div>
                      </div>
                      <div className="col-12">
                        <div className="form-group mb-3">
                          <label>Fecha</label>
                          <input
                            type="date"
                            className="form-control"
                            onChange={formik.handleChange}
                            name="fecha"
                            value={formik.values.fecha || ""}
                          />
                          {formik.errors.fecha &&
                            formik.touched.fecha &&
                            formik.errors.fecha}
                        </div>
                      </div>
                      <div className="col-12">
                        <div className="form-group mb-3">
                          <label>Hora Llegada</label>
                          <input
                            type="time"
                            className="form-control"
                            onChange={formik.handleChange}
                            name="hora_llegada"
                            value={formik.values.hora_llegada || ""}
                          />
                          {formik.errors.hora_llegada &&
                            formik.touched.hora_llegada &&
                            formik.errors.hora_llegada}
                        </div>
                      </div>
                      <div className="col-12">
                        <div className="form-group mb-3">
                          <label>Zona</label>
                          <select
                            className="form-control"
                            onChange={formik.handleChange}
                            name="zona"
                            value={formik.values.zona || ""}
                          >
                            <option>Seleccione una opción</option>
                            <option value="urbana">Urbana</option>
                            <option value="rural">Rural</option>
                          </select>
                          {formik.errors.zona &&
                            formik.touched.zona &&
                            formik.errors.zona}
                        </div>
                      </div>
                      <div className="col-12">
                        <div className="form-group mb-3">
                          <label>No Móvil</label>
                          <select
                            className="form-control"
                            onChange={formik.handleChange}
                            name="no_movil"
                            value={formik.values.no_movil || ""}
                          >
                            <option>Seleccione una opción</option>
                            <option>43</option>
                            <option>32</option>
                            <option>168</option>
                            <option>167</option>
                            <option>177</option>
                          </select>
                          {formik.errors.no_movil &&
                            formik.touched.no_movil &&
                            formik.errors.no_movil}
                        </div>
                      </div>
                      <div className="col-12">
                        <div className="form-group mb-3">
                          <label>Placa Móvil</label>
                          <select
                            className="form-control"
                            onChange={formik.handleChange}
                            name="placa_movil"
                            value={formik.values.placa_movil || ""}
                          >
                            <option>Seleccione una opción</option>
                            <option value="RIY 482">RIY 482</option>
                            <option value="GHA 470">GHA 470</option>
                            <option value="TVB 304">TVB 304</option>
                            <option value="RHK 345">RHK 345</option>
                            <option value="BWZ 949">BWZ 949</option>
                            <option value="DTV 016">DTV 016</option> 
                          </select>
                          {formik.errors.placa_movil &&
                            formik.touched.placa_movil &&
                            formik.errors.placa_movil}
                        </div>
                      </div>
                      <div className="col-12">
                        <div className="form-group mb-3">
                          <label>Lugar Origen Y/O Ocurrencia</label>
                          <input
                            type="text"
                            className="form-control"
                            onChange={formik.handleChange}
                            name="lugar_origen_ocurrencia"
                            value={formik.values.lugar_origen_ocurrencia || ""}
                          />
                          {formik.errors.lugar_origen_ocurrencia &&
                            formik.touched.lugar_origen_ocurrencia &&
                            formik.errors.lugar_origen_ocurrencia}
                        </div>
                      </div>
                      <div className="col-12">
                        <div className="form-group mb-3">
                          <label>Motivo y Descripción del traslado</label>
                          <input
                            type="text"
                            className="form-control"
                            onChange={formik.handleChange}
                            name="motivo_descripcion_traslado"
                            value={
                              formik.values.motivo_descripcion_traslado || ""
                            }
                          />
                          {formik.errors.motivo_descripcion_traslado &&
                            formik.touched.motivo_descripcion_traslado &&
                            formik.errors.motivo_descripcion_traslado}
                        </div>
                      </div>
                      <div className="col-12">
                        <div className="form-group mb-3">
                          <label>Tipo de Servicio</label>
                          <select
                            className="form-control"
                            onChange={formik.handleChange}
                            name="tipo_servicio"
                            value={formik.values.tipo_servicio || ""}
                          >
                            <option>Seleccionar una opción</option>
                            <option value="TAB">
                              TAB (Traslado Asistencial Basico)
                            </option>
                            <option value="TAM">
                              TAM (Traslado Asistencial Medicalizado)
                            </option>
                            <option value="APH">
                              APH (Atención Prehospitalaria)
                            </option>
                            <option value="RDO">RDO (Redondo)</option>
                            <option value="Sencillo">Sencillo</option>
                          </select>
                          {formik.errors.tipo_servicio &&
                            formik.touched.tipo_servicio &&
                            formik.errors.tipo_servicio}
                        </div>
                      </div>
                      <div className="col-12">
                        <div className="form-group mb-3">
                          <label>Servicio Prestado</label>
                          <select
                            className="form-control"
                            onChange={formik.handleChange}
                            name="servicio_prestado"
                            value={formik.values.servicio_prestado || ""}
                          >
                            <option>Seleccionar una opción</option>
                            <option value="Enfermedad General">
                              Enfermedad General
                            </option>
                            <option value="Accidente de Tránsito">
                              Accidente de Tránsito
                            </option>
                            <option value="Accidente Laboral">
                              Accidente Laboral
                            </option>
                            <option value="Accidente Estudiantil">
                              Accidente Estudiantil
                            </option>
                            <option value="Servicio Social">
                              Servicio Social
                            </option>
                            <option value="Otro">Otro</option>
                            <option value="No Aplica">No Aplica</option>
                          </select>
                          {formik.errors.servicio_prestado &&
                            formik.touched.servicio_prestado &&
                            formik.errors.servicio_prestado}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* DATOS DEL ACCIDENTE DE TRÁNSITO */}
            <div className="row layout-top-spacing">
              <div className="col-xl-12 col-lg-12 col-md-12 col-12 layout-spacing">
                <div className="statbox widget box box-shadow">
                  <div className="widget-header">
                    <div className="row">
                      <div className="col-xl-12 col-md-12 col-sm-12 col-12">
                        <h4>DATOS DEL ACCIDENTE DE TRÁNSITO</h4>
                      </div>
                    </div>
                  </div>
                  <div className="widget-content widget-content-area">
                    <div className="row">
                      <div className="col-12">
                        <div className="form-group mb-3">
                          <label>Condición del accidentado</label>
                          <select
                            className="form-control"
                            onChange={formik.handleChange}
                            name="condicion_accidentado"
                            value={formik.values.condicion_accidentado || ""}
                          >
                            <option>Seleccionar una opción</option>
                            <option value="Conductor">Conductor</option>
                            <option value="Ocupante">Ocupante</option>
                            <option value="Peaton">Peaton</option>
                            <option value="Ciclista">Ciclista</option>
                            <option value="Otro">Otro</option>
                            <option value="No Aplica">No Aplica</option>
                          </select>
                          {formik.errors.condicion_accidentado &&
                            formik.touched.condicion_accidentado &&
                            formik.errors.condicion_accidentado}
                        </div>
                      </div>
                      <div className="col-12">
                        <div className="form-group mb-3">
                          <label>Tipo de Vehículo</label>
                          <select
                            className="form-control"
                            onChange={formik.handleChange}
                            name="tipo_vehiculo"
                            value={formik.values.tipo_vehiculo || ""}
                          >
                            <option>Seleccionar una opción</option>
                            <option value="Motocicleta">Motocicleta</option>
                            <option value="Automovil">Automovil</option>
                            <option value="Camioneta">Camioneta</option>
                            <option value="Bus">Bus</option>
                            <option value="Micro Bus">Micro Bus</option>
                            <option value="Vehículo Pesado">
                              Vehículo Pesado
                            </option>
                            <option value="Otro">Otro</option>
                            <option value="No Aplica">No Aplica</option>
                          </select>
                          {formik.errors.tipo_vehiculo &&
                            formik.touched.tipo_vehiculo &&
                            formik.errors.tipo_vehiculo}
                        </div>
                      </div>
                      <div className="col-12">
                        <div className="form-group mb-3">
                          <label>Placa</label>
                          <input
                            type="text"
                            className="form-control"
                            onChange={formik.handleChange}
                            name="placa"
                            value={formik.values.placa || ""}
                          />
                          {formik.errors.placa &&
                            formik.touched.placa &&
                            formik.errors.placa}
                        </div>
                      </div>
                      <div className="col-12">
                        <div className="form-group mb-3">
                          <label>Marca</label>
                          <input
                            type="text"
                            className="form-control"
                            onChange={formik.handleChange}
                            name="marca"
                            value={formik.values.marca || ""}
                          />
                          {formik.errors.marca &&
                            formik.touched.marca &&
                            formik.errors.marca}
                        </div>
                      </div>
                      <div className="col-12">
                        <div className="form-group mb-3">
                          <label>Aseguradora</label>
                          <input
                            type="text"
                            className="form-control"
                            onChange={formik.handleChange}
                            name="aseguradora"
                            value={formik.values.aseguradora || ""}
                          />
                          {formik.errors.aseguradora &&
                            formik.touched.aseguradora &&
                            formik.errors.aseguradora}
                        </div>
                      </div>
                      <div className="col-12">
                        <div className="form-group mb-3">
                          <label>Póliza</label>
                          <input
                            type="text"
                            className="form-control"
                            onChange={formik.handleChange}
                            name="poliza"
                            value={formik.values.poliza || ""}
                          />
                          {formik.errors.poliza &&
                            formik.touched.poliza &&
                            formik.errors.poliza}
                        </div>
                      </div>
                      <div className="col-12">
                        <div className="form-group mb-3">
                          <label>AT</label>
                          <input
                            type="text"
                            className="form-control"
                            onChange={formik.handleChange}
                            name="at"
                            value={formik.values.at || ""}
                          />
                          {formik.errors.at &&
                            formik.touched.at &&
                            formik.errors.at}
                        </div>
                      </div>
                      <div className="col-12">
                        <div className="form-group mb-3">
                          <label>No</label>
                          <input
                            type="text"
                            className="form-control"
                            onChange={formik.handleChange}
                            name="no_poliza"
                            value={formik.values.no_poliza || ""}
                          />
                          {formik.errors.no_poliza &&
                            formik.touched.no_poliza &&
                            formik.errors.no_poliza}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* EXAMEN FÍSICO EN INICIO DEL TRASLADO */}
            <div className="row layout-top-spacing">
              <div className="col-xl-12 col-lg-12 col-md-12 col-12 layout-spacing">
                <div className="statbox widget box box-shadow">
                  <div className="widget-header">
                    <div className="row">
                      <div className="col-xl-12 col-md-12 col-sm-12 col-12">
                        <h4>EXAMEN FÍSICO EN INICIO DEL TRASLADO</h4>
                      </div>
                    </div>
                  </div>
                  <div className="widget-content widget-content-area">
                    <div className="row">
                      <div className="col-12">
                        <div className="form-group mb-3">
                          <label>Escala de Glassgow</label>
                          <select
                            className="form-control"
                            onChange={formik.handleChange}
                            name="escala_glassgow"
                            value={formik.values.escala_glassgow || ""}
                          >
                            <option>Seleccionar opción</option>
                            <option value="1">1/15</option>
                            <option value="2">2/15</option>
                            <option value="3">3/15</option>
                            <option value="4">4/15</option>
                            <option value="5">5/15</option>
                            <option value="6">6/15</option>
                            <option value="7">7/15</option>
                            <option value="8">8/15</option>
                            <option value="9">9/15</option>
                            <option value="10">10/15</option>
                            <option value="11">11/15</option>
                            <option value="12">12/15</option>
                            <option value="13">13/15</option>
                            <option value="14">14/15</option>
                            <option value="15">15/15</option>
                            <option value="No Aplica">No Aplica</option>
                          </select>
                          {formik.errors.escala_glassgow &&
                            formik.touched.escala_glassgow &&
                            formik.errors.escala_glassgow}
                        </div>
                      </div>
                      <div className="col-12">
                        <div className="form-group mb-3">
                          <label>Frecuencia Respiratoria /MIN</label>
                          <input
                            type="number"
                            className="form-control"
                            onChange={formik.handleChange}
                            name="frecuencia_respiratoria"
                            value={formik.values.frecuencia_respiratoria || ""}
                          />
                          {formik.errors.frecuencia_respiratoria &&
                            formik.touched.frecuencia_respiratoria &&
                            formik.errors.frecuencia_respiratoria}
                        </div>
                      </div>
                      <div className="col-12">
                        <div className="form-group mb-3">
                          <label>Frecuencia Cardiaca /MIN</label>
                          <input
                            type="number"
                            className="form-control"
                            onChange={formik.handleChange}
                            name="frecuencia_cardiaca"
                            value={formik.values.frecuencia_cardiaca || ""}
                          />
                          {formik.errors.frecuencia_cardiaca &&
                            formik.touched.frecuencia_cardiaca &&
                            formik.errors.frecuencia_cardiaca}
                        </div>
                      </div>
                      <div className="col-12">
                        <div className="form-group mb-3">
                          <label>Saturación de Oxígeno /SPO2</label>
                          <input
                            type="number"
                            className="form-control"
                            onChange={formik.handleChange}
                            name="saturacion"
                            value={formik.values.saturacion || ""}
                          />
                          {formik.errors.saturacion &&
                            formik.touched.saturacion &&
                            formik.errors.saturacion}
                        </div>
                      </div>
                      <div className="col-12">
                        <div className="form-group mb-3">
                          <label>Temperatura /Grados</label>
                          <input
                            type="number"
                            className="form-control"
                            onChange={formik.handleChange}
                            name="temperatura"
                            value={formik.values.temperatura || ""}
                          />
                          {formik.errors.temperatura &&
                            formik.touched.temperatura &&
                            formik.errors.temperatura}
                        </div>
                      </div>
                      <div className="col-12">
                        <div className="form-group mb-3">
                          <label>Glucometría </label>
                          <input
                            type="number"
                            className="form-control"
                            onChange={formik.handleChange}
                            name="glucometria"
                            value={formik.values.glucometria || ""}
                          />
                          {formik.errors.glucometria &&
                            formik.touched.glucometria &&
                            formik.errors.glucometria}
                        </div>
                      </div>
                      <div className="col-12">
                        <div className="form-group mb-3">
                          <label>Oxígeno </label>
                          <input
                            type="number"
                            className="form-control"
                            onChange={formik.handleChange}
                            name="oxigeno"
                            value={formik.values.oxigeno || ""}
                          />
                          {formik.errors.oxigeno &&
                            formik.touched.oxigeno &&
                            formik.errors.oxigeno}
                        </div>
                      </div>
                      <div className="col-12">
                        <div className="form-group mb-3">
                          <label>Presión arterial sistólica </label>
                          <input
                            type="number"
                            className="form-control"
                            onChange={formik.handleChange}
                            name="presion_arterial_sistolica"
                            value={
                              formik.values.presion_arterial_sistolica || ""
                            }
                          />
                          {formik.errors.presion_arterial_sistolica &&
                            formik.touched.presion_arterial_sistolica &&
                            formik.errors.presion_arterial_sistolica}
                        </div>
                      </div>
                      <div className="col-12">
                        <div className="form-group mb-3">
                          <label>Presión arterial diastólica </label>
                          <input
                            type="number"
                            className="form-control"
                            onChange={formik.handleChange}
                            name="presion_arterial_diastolica"
                            value={
                              formik.values.presion_arterial_diastolica || ""
                            }
                          />
                          {formik.errors.presion_arterial_diastolica &&
                            formik.touched.presion_arterial_diastolica &&
                            formik.errors.presion_arterial_diastolica}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* ANÁLISIS Y OBSERVACIONES DURANTE EL TRASLADO */}
            <div className="row layout-top-spacing">
              <div className="col-xl-12 col-lg-12 col-md-12 col-12 layout-spacing">
                <div className="statbox widget box box-shadow">
                  <div className="widget-header">
                    <div className="row">
                      <div className="col-xl-12 col-md-12 col-sm-12 col-12">
                        <h4>ANÁLISIS Y OBSERVACIONES DURANTE EL TRASLADO</h4>
                      </div>
                    </div>
                  </div>
                  <div className="widget-content widget-content-area">
                    <div className="row">
                      <div className="col-12">
                        <div className="form-group mb-3">
                          <textarea
                            className="form-control"
                            onChange={formik.handleChange}
                            name="analisis_observaciones_traslado"
                            defaultValue={
                              formik.values.analisis_observaciones_traslado ||
                              ""
                            }
                          ></textarea>
                          {formik.errors.analisis_observaciones_traslado &&
                            formik.touched.analisis_observaciones_traslado &&
                            formik.errors.analisis_observaciones_traslado}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* TRASLADO FINAL  IPS/HOSPITAL/CENTRO MÉDICO/ */}
            <div className="row layout-top-spacing">
              <div className="col-xl-12 col-lg-12 col-md-12 col-12 layout-spacing">
                <div className="statbox widget box box-shadow">
                  <div className="widget-header">
                    <div className="row">
                      <div className="col-xl-12 col-md-12 col-sm-12 col-12">
                        <h4>TRASLADO FINAL IPS/HOSPITAL/CENTRO MÉDICO/</h4>
                      </div>
                    </div>
                  </div>
                  <div className="widget-content widget-content-area">
                    <div className="row">
                      <div className="col-12">
                        <div className="form-group mb-3">
                          <label>Nombre Institucion </label>
                          <input
                            type="text"
                            className="form-control"
                            onChange={formik.handleChange}
                            name="nombre_institucion_traslado_final"
                            value={
                              formik.values.nombre_institucion_traslado_final ||
                              ""
                            }
                          />
                          {formik.errors.nombre_institucion_traslado_final &&
                            formik.touched.nombre_institucion_traslado_final &&
                            formik.errors.nombre_institucion_traslado_final}
                        </div>
                      </div>

                      <div className="col-12">
                        <div className="form-group mb-3">
                          <label>Servicio </label>
                          <input
                            type="text"
                            className="form-control"
                            onChange={formik.handleChange}
                            name="servicio_traslado_final"
                            value={formik.values.servicio_traslado_final || ""}
                          />
                          {formik.errors.servicio_traslado_final &&
                            formik.touched.servicio_traslado_final &&
                            formik.errors.servicio_traslado_final}
                        </div>
                      </div>

                      <div className="col-12">
                        <div className="form-group mb-3">
                          <label>Habitación/Cama </label>
                          <input
                            type="text"
                            className="form-control"
                            onChange={formik.handleChange}
                            name="habilitacion_cama_traslado_final"
                            value={
                              formik.values.habilitacion_cama_traslado_final ||
                              ""
                            }
                          />
                          {formik.errors.habilitacion_cama_traslado_final &&
                            formik.touched.habilitacion_cama_traslado_final &&
                            formik.errors.habilitacion_cama_traslado_final}
                        </div>
                      </div>

                      <div className="col-12">
                        <div className="form-group mb-3">
                          <label>Ciudad </label>
                          <input
                            type="text"
                            className="form-control"
                            onChange={formik.handleChange}
                            name="ciudad_traslado_final"
                            value={formik.values.ciudad_traslado_final || ""}
                          />
                          {formik.errors.ciudad_traslado_final &&
                            formik.touched.ciudad_traslado_final &&
                            formik.errors.ciudad_traslado_final}
                        </div>
                      </div>

                      <div className="col-12">
                        <div className="form-group mb-3">
                          <label>Departamento </label>
                          <input
                            type="text"
                            className="form-control"
                            onChange={formik.handleChange}
                            name="departamento_traslado_final"
                            value={
                              formik.values.departamento_traslado_final || ""
                            }
                          />
                          {formik.errors.departamento_traslado_final &&
                            formik.touched.departamento_traslado_final &&
                            formik.errors.departamento_traslado_final}
                        </div>
                      </div>

                      <div className="col-12">
                        <div className="form-group mb-3">
                          <label>Fecha </label>
                          <input
                            type="date"
                            className="form-control"
                            onChange={formik.handleChange}
                            name="fecha_traslado_final"
                            value={formik.values.fecha_traslado_final || ""}
                          />
                          {formik.errors.fecha_traslado_final &&
                            formik.touched.fecha_traslado_final &&
                            formik.errors.fecha_traslado_final}
                        </div>
                      </div>

                      <div className="col-12">
                        <div className="form-group mb-3">
                          <label>Hora </label>
                          <input
                            type="time"
                            className="form-control"
                            onChange={formik.handleChange}
                            name="hora_traslado_final"
                            value={formik.values.hora_traslado_final || ""}
                          />
                          {formik.errors.hora_traslado_final &&
                            formik.touched.hora_traslado_final &&
                            formik.errors.hora_traslado_final}
                        </div>
                      </div>
                      

                      <div className="col-12">
                        <div className="form-group mb-3">
                          <textarea className="form-control"></textarea>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row layout-top-spacing">
              <div className="col-xl-12 col-lg-12 col-md-12 col-12 layout-spacing">
                <div className="statbox widget box box-shadow">
                  <div className="widget-header">
                    <div className="row">
                      <div className="col-xl-12 col-md-12 col-sm-12 col-12">
                        <h4>FIRMA DEL MÉDICO</h4>
                      </div>
                    </div>
                  </div>
                  <div className="widget-content widget-content-area">
                    <div className="row">
                    <div className="col-12">
                        <div className="form-group mb-3">
                          <CanvasDraw
                            ref={DrawMedico}
                            brushColor={"black"}
                            brushRadius={1}
                            lazyRadius={1}
                            canvasHeight={200}
                            canvasWidth={600}
                            saveData={formik.values.firma_medico ? formik.values.firma_medico : null}
                          />
                        </div>
                        <br/>
                          <button type="button" className="btn btn-danger" onClick={ClearDrawMedico}>Limpiar</button>
                          <button type="button" className="btn btn-success" onClick={SaveDrawMedico}>Guardar firma</button>
                      </div>

                      <div className="col-12">
                        <div className="form-group mb-3">
                          <label>Documento o Registro médico</label>
                          <input
                            type="text"
                            className="form-control"
                            onChange={formik.handleChange}
                            name="registro_medico"
                            value={formik.values.registro_medico || ""}
                          />
                          {formik.errors.registro_medico &&
                            formik.touched.registro_medico &&
                            formik.errors.registro_medico}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row layout-top-spacing">
              <div className="col-xl-12 col-lg-12 col-md-12 col-12 layout-spacing">
                <div className="statbox widget box box-shadow">
                  <div className="widget-header">
                    <div className="row">
                      <div className="col-xl-12 col-md-12 col-sm-12 col-12">
                        <h4>CONSENTIMIENTO INFORMADO/</h4>
                      </div>
                    </div>
                  </div>
                  <div className="widget-content widget-content-area">
                    <div className="row">
                    <div className="col-12">
                        <div className="form-group mb-3">
                          <p>
                          He comprendido lo que se me ha explicado; doy mi consentimiento y autorizo al equipo correspondiente para llevar a cabo el traslado y efectuar los procedimientos terapéuticos que se consideren indicados. Sé también que puedo retractarme de la firma de este consentimiento previo al traslado, haciendo mención expresa de ello y firmando al lado de la firma del consentimiento
                          </p>
                        </div>
                      </div>
                      <div className="col-12">
                        <div className="form-group mb-3">
                          <label>Autorizo Traslado</label>
                          <select
                            className="form-control"
                            onChange={formik.handleChange}
                            name="autoriza_traslado"
                            value={formik.values.autoriza_traslado || ""}
                          >
                            <option>Seleccionar Opción</option>
                            <option value="si">Si</option>
                            <option value="no">No</option>
                            <option value="noaplica">No Aplica</option>
                          </select>
                          
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row layout-top-spacing">
              <div className="col-xl-12 col-lg-12 col-md-12 col-12 layout-spacing">
                <div className="statbox widget box box-shadow">
                  <div className="widget-header">
                    <div className="row">
                      <div className="col-xl-12 col-md-12 col-sm-12 col-12">
                        <h4>FIRMA DEL PACIENTE / ACOMPAÑANTE</h4>
                      </div>
                    </div>
                  </div>
                  <div className="widget-content widget-content-area">
                    <div className="row">
                    <div className="col-12">
                        <div className="form-group mb-3">
                          <CanvasDraw
                            ref={DrawPaciente}
                            brushColor={"black"}
                            brushRadius={1}
                            lazyRadius={1}
                            
                            canvasHeight={200}
                            canvasWidth={200}
                            saveData={formik.values.firma_paciente ? formik.values.firma_paciente : null }
                          />
                          <button type="button" className="btn btn-danger" onClick={ClearDrawPaciente}>Limpiar</button>
                          <button type="button" className="btn btn-success" onClick={SaveDrawPaciente}>Guardar firma</button>
                        </div>
                      </div>

                      <div className="col-12">
                        <div className="form-group mb-3">
                          <label>Documento Paciente / Acompañante</label>
                          <input
                            type="number"
                            className="form-control"
                            onChange={formik.handleChange}
                            name="documento_paciente"
                            value={formik.values.documento_paciente || ""}
                          />
                          {formik.errors.documento_paciente &&
                            formik.touched.documento_paciente &&
                            formik.errors.documento_paciente}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row layout-top-spacing">
              <div className="col-xl-12 col-lg-12 col-md-12 col-12 layout-spacing">
                <div className="statbox widget box box-shadow">
                  <div className="widget-header">
                    <div className="row">
                      <div className="col-xl-12 col-md-12 col-sm-12 col-12">
                        <h4>FIRMA DEL TRIPULANTE DE LA AMBULANCIA</h4>
                      </div>
                    </div>
                  </div>
                  <div className="widget-content widget-content-area">
                    <div className="row">
                    <div className="col-12">
                        <div className="form-group mb-3">
                          <CanvasDraw
                            ref={DrawTripulante}
                            brushColor={"black"}
                            brushRadius={1}
                            lazyRadius={1}
                            
                            canvasHeight={200}
                            saveData={formik.values.tripulante_ambulancia ? formik.values.tripulante_ambulancia : null }
                          />
                          <br/>
                          <button type="button" className="btn btn-danger" onClick={ClearDrawTripulante}>Limpiar</button>
                          <button type="button" className="btn btn-success" onClick={SaveDrawTripulante}>Guardar firma</button>
                        </div>
                      </div>

                      <div className="col-12">
                        <div className="form-group mb-3">
                          <label>Documento Tripulante Ambulancia</label>
                          <input
                            type="number"
                            className="form-control"
                            onChange={formik.handleChange}
                            name="documento_tripulante_ambulancia"
                            value={formik.values.documento_tripulante_ambulancia || ""}
                          />
                          {formik.errors.documento_tripulante_ambulancia &&
                            formik.touched.documento_tripulante_ambulancia &&
                            formik.errors.documento_tripulante_ambulancia}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row layout-top-spacing">
              <div className="col-xl-12 col-lg-12 col-md-12 col-12 layout-spacing">
                <div className="statbox widget box box-shadow">
                  <div className="widget-header">
                    <div className="row">
                      <div className="col-xl-12 col-md-12 col-sm-12 col-12">
                        <h4>FIRMA DEL CONDUCTOR DE LA AMBULANCIA</h4>
                      </div>
                    </div>
                  </div>
                  <div className="widget-content widget-content-area">
                    <div className="row">
                    <div className="col-12" id="examplePrint">
                        <div className="form-group mb-3">
                          <CanvasDraw
                            ref={DrawConductor}
                            brushColor={"black"}
                            brushRadius={1}
                            lazyRadius={1}
                            
                            canvasHeight={200}
                            saveData={formik.values.conductor_ambulancia ? formik.values.conductor_ambulancia : null }
                          />
                          <br/>
                          <button type="button" className="btn btn-danger" onClick={ClearDrawConductor}>Limpiar</button>
                          <button type="button" className="btn btn-success" onClick={SaveDrawConductor}>Guardar firma</button>
                        </div>
                      </div>

                      <div className="col-12">
                        <div className="form-group mb-3">
                          <label>Documento </label>
                          <input
                            type="number"
                            className="form-control"
                            onChange={formik.handleChange}
                            name="documento_conductor_ambulancia"
                            value={formik.values.documento_conductor_ambulancia || ""}
                          />
                          {formik.errors.documento_conductor_ambulancia &&
                            formik.touched.documento_conductor_ambulancia &&
                            formik.errors.documento_conductor_ambulancia}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* FIN DEL FORMULARIO */}
            
              <button type="submit" className="btn btn-success">
                {!loading ? 'Guardar Cambios Historia Clínica' : 'Guardando...'}
              </button>
              <Link to={'/historias/imprimir/' + formik.values.id} target='_blank' className="btn btn-primary">PDF</Link>
          </>
          </div>
      </form>

        </>
        :
        'Cargando...'
    }
        
    </>
  );
}

export default VerHistoria;
