import React from 'react'
import { Route } from 'react-router-dom'

function AppRoute({ component: Component, ...rest }){
  return(
    <Route
      {...rest}
      render={props => (
        <Component {...props}/>
      )}
    />
  )
}
export default AppRoute;