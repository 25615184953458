import { createStore, combineReducers, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import {uiReducer} from './reducers';
import { composeWithDevTools } from 'redux-devtools-extension';
const reducers = combineReducers({
  ui: uiReducer
})

const composedEnhancer = composeWithDevTools(applyMiddleware(thunk))

const store = createStore(reducers,composedEnhancer);

export default store;