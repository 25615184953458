import React from 'react';
import axios from 'axios';
import Config from '../../../Config';
import { Link } from 'react-router-dom';
import { Container, Row, Col, Card, Button, Form } from "react-bootstrap";
function ListarHistorias(){

    const [dataHC, setDataHC] = React.useState(null);
    const [dataSearch, setDataSearch] = React.useState(null);
    const [desde, setDesde] = React.useState(null);
    const [hasta, setHasta] = React.useState(null);
    const [loading, setLoading] = React.useState(false);

    React.useEffect(()=>{

        ObtenerHistorias();

    },[])

    React.useEffect(()=>{
        if(hasta){
            searchByDate();
        }
    },[desde,hasta])

    async function ObtenerHistorias(){
        setLoading(true);
        await axios.get(Config.url +'historia-clinicas?_limit=30&_sort=createdAt:DESC', { headers: { 'Authorization': 'Bearer ' + localStorage.getItem('token') } } )
        .then(response=>{
            console.log(response);
            setDataHC(response.data);
            setLoading(false);
        })
        .catch(error =>{
            console.log(error);
            setLoading(false);
        })

    }

    const searchByDate = async () => {
        setLoading(true);
        await axios.get(Config.url + 'historia-clinicas?createdAt_gte='+ desde+'&createdAt_lte='+hasta, { headers: { 'Authorization': 'Bearer ' + localStorage.getItem('token') } })
            .then(response => {
                setDataHC(response.data);
                setLoading(false);
            })
            .catch(error => {
              console.log(error);
              setLoading(false);
            })
    
      }

    const searchByCC = async () => {
        
        if (dataSearch != '') {
    
          await axios.get(Config.url + 'historia-clinicas?paciente.numero_documento=' + dataSearch, { headers: { 'Authorization': 'Bearer ' + localStorage.getItem('token') } })
            .then(response => {
                setDataHC(response.data);
            })
            .catch(error => {
              console.log(error);
            })
    
        } else {
            ObtenerHistorias();
        }
    
      }

    return(
        <>
        <Row>
            <Col>
            <Card>
                <Card.Header>
                    <Row>
                        <Col>
                        <h4>LISTADO DE HISTORIAS CLÍNICAS</h4>
                        </Col>
                        <Col className="text-right">
                            <Link to="/historias/nuevo">
                            <Button variant="primary">Nueva Historia</Button>
                            </Link>
                            
                        </Col>
                    </Row>

                </Card.Header>
                <Card.Body>
                    <Row>
                        <Col lg={12}>
                        <Form.Group>
                            <Form.Control type="text" value={dataSearch} onBlur={(e)=>{e.preventDefault();setDataSearch(e.target.value)}} placeholder="Buscar por cedula" />
                            </Form.Group>
                        </Col>
                        
                    </Row>
                    <Row>
                        <Col lg={6}>
                            <label>Desde</label>
                            <Form.Group>
                                <Form.Control type="date"  onBlur={(e)=>{e.preventDefault();setDesde(e.target.value)}} />
                            </Form.Group>
                        </Col>
                        <Col lg={6}>
                        <label>Hasta</label>
                            <Form.Group>
                                <Form.Control type="date"  onBlur={(e)=>{e.preventDefault();setHasta(e.target.value)}} />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                    <Col>
                        <div className="form-group">
                            <Button variant="secondary" onClick={(e)=>{searchByCC();}}>Buscar</Button>
                            <Button variant="warning" onClick={(e)=>{ObtenerHistorias(); setDataSearch(''); setDesde(null); setHasta(null)}}>Borrar Busqueda</Button>
                        </div>
                    </Col>
                        
                    </Row>
                    {!loading
                    ?
                    <Row>
                        <Col>
                        <div className="table-responsive">
                                  <table className="table">
                                      <thead>
                                          <th>Documento</th>
                                          <th>Paciente</th>
                                          <th>Fecha</th>
                                          <th>Movil</th>
                                          <th>Servicio</th>
                                          <th>Placa</th>
                                          <th>Autoriza Traslado</th>
                                          <th>Opciones</th>

                                      </thead>
                                      <tbody>
                                        
                                          {dataHC ? dataHC.map((data,index)=>{
                                              return(
                                                  
                                                  <tr key={index}>
                                                  <td>{data.paciente.tipo_documento} {data.paciente.numero_documento}</td>
                                                  <td>{data.paciente.nombre_completo}</td>
                                                  <td>{new Date(data.createdAt).toLocaleDateString()}</td>
                                                  <td>{data.no_movil}</td>
                                                  <td>{data.servicio_prestado}</td>
                                                  <td>{data.placa}</td>
                                                  <td>{data.autoriza_traslado}</td>
                                                  <td>
                                                      <Link to={'/historias/ver/' + data._id} className="btn btn-success">Ver</Link>
                                                      <Link to={'/historias/imprimir/' + data._id} target='_blank' className="btn btn-primary">PDF</Link>
                                                      
                                                  </td>
                                                  </tr>
                                                  
                                              )
                                          })
                                          : 
                                          'Cargandoo...'
                                        }
                                      </tbody>
                                  </table>
                              </div>
                        </Col>
                    </Row>
                    :
                    <>Cargando...</>
                    }

                </Card.Body>
            </Card>
            </Col>

        </Row>
        

        </>
    )

}

export default ListarHistorias;