import React from 'react';
import App from '../App';
import { Link } from 'react-router-dom';
import Navbar from '../Components/Navbar'
import { useHistory } from "react-router-dom";
function PrintLayout({ children }) {
    const history = useHistory();
    const [isNavCollapsed, setIsNavCollapsed] = React.useState(false);
    const location = history.location.pathname;
    return (
        <>
            {children}

        </>

    );


}


export default PrintLayout;