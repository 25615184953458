import React from "react";
import axios from "axios";
import Config from "../../../Config";
import { useFormik } from "formik";
import { ToastContainer, toast } from "react-toastify";
import CanvasDraw from "react-canvas-draw";
import { useParams, useHistory } from 'react-router-dom';
function NuevoPaciente() {
  //Hooks
  const [loading, setLoading] = React.useState(false);
  const [PacienteExiste, setPacienteExiste] = React.useState(null);
  
  //Step 0 -> Buscar el paciente
  //Step 1 -> Crear el paciente si no existe o Mostrar los datos del paciente
  
  const history = useHistory();
  
  //Formik
  const formik = useFormik({
    initialValues: {},
    async onSubmit(values) {
      //Función para crear el paciente en caso que no exista
      if (!PacienteExiste) {
        await axios
          .post(Config.url + "pacientes", values )
          .then((response) => {
            console.log(response);
            localStorage.removeItem('SaveDrawMedico');
            localStorage.removeItem('SaveDrawPaciente');
            localStorage.removeItem('SaveDrawTripulante');
            localStorage.removeItem('SaveDrawConductor');            
            toast.success('Paciente creado con éxito');
          })
          .catch((error) => {
            console.error(error);
            toast.error('No se ha podido crear el paciente');
          });
      }
      console.log(values);
    },
  });

  //Functions
  async function BuscarPaciente() {
    localStorage.removeItem('SaveDrawMedico');
    localStorage.removeItem('SaveDrawPaciente');
    localStorage.removeItem('SaveDrawTripulante');
    localStorage.removeItem('SaveDrawConductor');
    setLoading(true);
    let tipoDocumento = formik.values.tipo_documento;
    let numeroDocumento = formik.values.numero_documento;
    console.log(tipoDocumento);

    const headers = {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("siteToken"),
      },
    };
    await axios
      .get(
        Config.url +
          "pacientes?tipo_documento=" +
          tipoDocumento +
          "&numero_documento=" +
          numeroDocumento +
          "", 
          { headers: { 'Authorization': 'Bearer ' + localStorage.getItem('token') } } 
      )
      .then((response) => {
        setLoading(false);
        console.log(response);
        let data = response.data[0];
        if (response.data.length > 0) {
        setPacienteExiste(true);
        toast.error("El Paciente ya existe");
        }
      })
      .catch((error) => {
        setLoading(false);
        console.error(error);
        setPacienteExiste(false);
    });
  }

  return (
    <>
      <ToastContainer />
      <form onSubmit={formik.handleSubmit}>
        {/* INICIO DEL FORMULARIO */}

        {/* DATOS DEL PACIENTE */}
        <div className="row layout-top-spacing">
          <div className="col-xl-12 col-lg-12 col-md-12 col-12 layout-spacing">
            <div className="statbox widget box box-shadow">
              <div className="widget-header">
                <div className="row">
                  <div className="col-xl-12 col-md-12 col-sm-12 col-12">
                    <h4>DATOS DEL PACIENTE</h4>
                  </div>
                </div>
              </div>
              <div className="widget-content widget-content-area">
                <div className="row">
                  <div className="col-12">
                    <div className="input-group mb-4">
                      <div className="input-group-prepend">
                        <select
                          className="form-control"
                          onChange={formik.handleChange}
                          name="tipo_documento"
                          value={formik.values.tipo_documento || ""}
                        >
                          <option>Tipo Documento</option>
                          <option value="CC">Cédula de Ciudadanía</option>
                          <option value="CE">Cédula de Extranjería</option>
                          <option value="TI">Tarjeta de Identidad</option>
                          <option value="NUIP">Numero Unico de Identificación (NUIP)</option>
                          <option value="REGC">Registro Civil</option>
                        </select>
                      </div>
                      <input
                        type="number"
                        className="form-control"
                        onChange={(e)=>{
                            BuscarPaciente();
                            formik.handleChange(e);
                        }}
                        onBlur={(e)=>{
                            BuscarPaciente();
                        }}
                        name="numero_documento"
                        value={formik.values.numero_documento || ""}
                      />
                      {formik.errors.numero_documento &&
                        formik.touched.numero_documento &&
                        formik.errors.numero_documento}
                    </div>
                  </div>

                    <>
                    <div className="col-12">
                    <div className="form-group mb-3">
                    <label>Institucion</label>
                        <select
                          className="form-control"
                          onChange={formik.handleChange}
                          name="institucion"
                          value={formik.values.institucion || ""}
                        >
                          <option>Institucion</option>
                          <option value="PARTICULAR">Particular</option>
                          <option value="JARDIN INFANTIL RAYUELA">Jardín Infantil Rayuela</option>
                        </select>
                      
                      {formik.errors.institucion &&
                        formik.touched.institucion &&
                        formik.errors.institucion}
                    </div>
                    </div>
                      <div className="col-12">
                        <div className="form-group mb-3">
                          <label>Nombre Completo</label>
                          <input
                            type="text"
                            className="form-control"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            name="nombre_completo"
                            value={formik.values.nombre_completo || ""}
                          />
                          {formik.errors.nombre_completo &&
                            formik.touched.nombre_completo &&
                            formik.errors.nombre_completo}
                        </div>
                      </div>

                      <div className="col-12">
                        <div className="form-group mb-3">
                          <label>Fecha de Nacimiento</label>
                          <input
                            type="date"
                            className="form-control"
                            onChange={formik.handleChange}
                            name="fecha_nacimiento"
                            value={formik.values.fecha_nacimiento || ""}
                          />
                          {formik.errors.fecha_nacimiento &&
                            formik.touched.fecha_nacimiento &&
                            formik.errors.fecha_nacimiento}
                        </div>
                      </div>

                      <div className="col-12">
                        <div className="form-group mb-3">
                          <label>Edad</label>
                          <input
                            type="text"
                            className="form-control"
                            onChange={formik.handleChange}
                            name="edad"
                            value={formik.values.edad || ""}
                          />
                          {formik.errors.edad &&
                            formik.touched.edad &&
                            formik.errors.edad}
                        </div>
                      </div>

                      <div className="col-12">
                        <div className="form-group mb-3">
                          <label>Sexo</label>
                          <select
                            className="form-control"
                            onChange={formik.handleChange}
                            name="sexo"
                            value={formik.values.sexo || ""}
                          >
                            <option>Seleccionar Opción</option>
                            <option value="Masculino">Masculino</option>
                            <option value="Femenino">Femenino</option>
                            <option value="Sin Definir">Sin Definir</option>
                          </select>
                          {formik.errors.sexo &&
                            formik.touched.sexo &&
                            formik.errors.sexo}
                        </div>
                      </div>

                      <div className="col-12">
                        <div className="form-group mb-3">
                          <label>EPS</label>
                          <input
                            type="text"
                            className="form-control"
                            onChange={formik.handleChange}
                            name="eps"
                            value={formik.values.eps || ""}
                          />
                          {formik.errors.eps &&
                            formik.touched.eps &&
                            formik.errors.eps}
                        </div>
                      </div>

                      <div className="col-12">
                        <div className="form-group mb-3">
                          <label>Estado Civil</label>
                          <select
                            className="form-control"
                            onChange={formik.handleChange}
                            name="estado_civil"
                            value={formik.values.estado_civil || ""}
                          >
                            <option>Seleccionar Opción</option>
                            <option value="Soltero">Soltero</option>
                            <option value="Casado">Casado</option>
                            <option value="Union Libre">Union Libre</option>
                            <option value="Viudo">Viudo</option>
                          </select>
                          {formik.errors.estado_civil &&
                            formik.touched.estado_civil &&
                            formik.errors.estado_civil}
                        </div>
                      </div>

                      <div className="col-12">
                        <div className="form-group mb-3">
                          <label>Dirección</label>
                          <input
                            type="text"
                            className="form-control"
                            onChange={formik.handleChange}
                            name="direccion"
                            value={formik.values.direccion || ""}
                          />
                          {formik.errors.direccion &&
                            formik.touched.direccion &&
                            formik.errors.direccion}
                        </div>
                      </div>

                      <div className="col-12">
                        <div className="form-group mb-3">
                          <label>Ocupación</label>
                          <input
                            type="text"
                            className="form-control"
                            onChange={formik.handleChange}
                            name="ocupacion"
                            value={formik.values.ocupacion || ""}
                          />
                          {formik.errors.ocupacion &&
                            formik.touched.ocupacion &&
                            formik.errors.ocupacion}
                        </div>
                      </div>

                      <div className="col-12">
                        <div className="form-group mb-3">
                          <label>Teléfono</label>
                          <input
                            type="number"
                            className="form-control"
                            onChange={formik.handleChange}
                            name="telefono"
                            value={formik.values.telefono || ""}
                          />
                          {formik.errors.telefono &&
                            formik.touched.telefono &&
                            formik.errors.telefono}
                        </div>
                      </div>

                      <div className="col-12">
                        <div className="form-group mb-3">
                          <label>Ciudad</label>
                          <input
                            type="text"
                            className="form-control"
                            onChange={formik.handleChange}
                            name="ciudad"
                            value={formik.values.ciudad || ""}
                          />
                          {formik.errors.ciudad &&
                            formik.touched.ciudad &&
                            formik.errors.ciudad}
                        </div>
                      </div>

                      <div className="col-12">
                        <div className="form-group mb-3">
                          <label>Departamento</label>
                          <input
                            type="text"
                            className="form-control"
                            onChange={formik.handleChange}
                            name="departamento"
                            value={formik.values.departamento || ""}
                          />
                          {formik.errors.departamento &&
                            formik.touched.departamento &&
                            formik.errors.departamento}
                        </div>
                      </div>

                      <div className="col-12">
                        <div className="form-group mb-3">
                          <label>Nombre Acompañante</label>
                          <input
                            type="text"
                            className="form-control"
                            onChange={formik.handleChange}
                            name="nombre_acompa"
                            value={formik.values.nombre_acompa || ""}
                          />
                          {formik.errors.nombre_acompa &&
                            formik.touched.nombre_acompa &&
                            formik.errors.nombre_acompa}
                        </div>
                      </div>

                      <div className="col-12">
                        <div className="form-group mb-3">
                          <label>Parentezco</label>
                          <input
                            type="text"
                            className="form-control"
                            onChange={formik.handleChange}
                            name="parentesco"
                            value={formik.values.parentesco || ""}
                          />
                          {formik.errors.parentesco &&
                            formik.touched.parentesco &&
                            formik.errors.parentesco}
                        </div>
                      </div>

                      <div className="col-12">
                        <div className="form-group mb-3">
                          <label>Diagnóstico de Enfermedades</label>
                          <input
                            type="text"
                            className="form-control"
                            onChange={formik.handleChange}
                            name="diagnostico_enfermedades"
                            value={formik.values.diagnostico_enfermedades || ""}
                          />
                          {formik.errors.diagnostico_enfermedades &&
                            formik.touched.diagnostico_enfermedades &&
                            formik.errors.diagnostico_enfermedades}
                        </div>
                      </div>

                      <h3>Antecedentes Patológicos</h3>

                      <div className="col-12">
                        <div className="form-group mb-3">
                          <label>Alergias</label>
                          <input
                            type="text"
                            className="form-control"
                            onChange={formik.handleChange}
                            name="antecedentespatologicos_alergias"
                            value={
                              formik.values.antecedentespatologicos_alergias ||
                              ""
                            }
                          />
                          {formik.errors.antecedentespatologicos_alergias &&
                            formik.touched.antecedentespatologicos_alergias &&
                            formik.errors.antecedentespatologicos_alergias}
                        </div>
                      </div>

                      <div className="col-12">
                        <div className="form-group mb-3">
                          <label>Familiares</label>
                          <input
                            type="text"
                            className="form-control"
                            onChange={formik.handleChange}
                            name="antecedentespatologicos_familiares"
                            value={
                              formik.values
                                .antecedentespatologicos_familiares || ""
                            }
                          />
                          {formik.errors.antecedentespatologicos_familiares &&
                            formik.touched.antecedentespatologicos_familiares &&
                            formik.errors.antecedentespatologicos_familiares}
                        </div>
                      </div>

                      <div className="col-12">
                        <div className="form-group mb-3">
                          <label>Cirugias</label>
                          <input
                            type="text"
                            className="form-control"
                            onChange={formik.handleChange}
                            name="antecedentespatologicos_cirugias"
                            value={
                              formik.values.antecedentespatologicos_cirugias ||
                              ""
                            }
                          />
                          {formik.errors.antecedentespatologicos_cirugias &&
                            formik.touched.antecedentespatologicos_cirugias &&
                            formik.errors.antecedentespatologicos_cirugias}
                        </div>
                      </div>

                      <div className="col-12">
                        <div className="form-group mb-3">
                          <label>Medicamentos</label>
                          <input
                            type="text"
                            className="form-control"
                            onChange={formik.handleChange}
                            name="antecedentespatologicos_medicamentos"
                            value={
                              formik.values
                                .antecedentespatologicos_medicamentos || ""
                            }
                          />
                          {formik.errors.antecedentespatologicos_medicamentos &&
                            formik.touched
                              .antecedentespatologicos_medicamentos &&
                            formik.errors.antecedentespatologicos_medicamentos}
                        </div>
                      </div>
                    </>
                  
                </div>
                
                  {!PacienteExiste && 
                  <button type="submit" className="btn btn-success">
                  Crear Paciente
                </button>
                }
                
              </div>
            </div>
          </div>
        </div>
        
      </form>
    </>
  );
}

export default NuevoPaciente;
