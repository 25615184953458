import types from '../types';

export const uiReducer = (state = {}, action) => {

    switch(action.type){
        // case types.closenNavParent : {
        //     return {
        //         ...state,
        //         openNavParent: action.payload
        //     };
        // }
        default:
            return state;
    }

}

