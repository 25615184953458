import React from "react";
import axios from "axios";
import Config from "../../../Config";
import { useFormik } from "formik";
import { ToastContainer, toast } from "react-toastify";
import CanvasDraw from "react-canvas-draw";
import { useParams, useHistory } from "react-router-dom";
import domtoimage from "dom-to-image";
import { jsPDF } from "jspdf";
import html2pdf from "html2pdf.js";
import html2canvas from "html2canvas";
function ImprimirHistoria() {
  //Hooks
  const [loading, setLoading] = React.useState(false);
  const [dataPaciente, setDataPaciente] = React.useState(null);
  //Step 0 -> Buscar el paciente
  //Step 1 -> Crear el paciente si no existe o Mostrar los datos del paciente
  const urlParams = useParams();
  const DrawMedico = React.useRef();
  const DrawPaciente = React.useRef();
  const DrawConductor = React.useRef();
  const DrawTripulante = React.useRef();

  React.useEffect(() => {
    if (urlParams.IdHistoria) {
      BuscarHistoria();
    }
  }, []);

  const Imprimir = async (data) => {
    setLoading(true);
    const input = document.getElementById("printarea");
    const input2 = document.getElementById("printarea2");
    const input3 = document.getElementById("printarea3");
    //html2pdf(input);
    //const pdf = new jsPDF();
    const doc = new jsPDF("p" , "px" , "a4");
    if (doc) {
    await  domtoimage
        .toPng(input)
        .then((imgData) => {
          let img = new Image();
          img.src = imgData;
          console.log(imgData);
          // pdf.addImage(img, 'JPG', 0, 0);
          // pdf.save('download.pdf');

          const bufferX = 15;
          const bufferY = 15;
          const imgProps = doc.getImageProperties(img);
          //const pdfWidth = doc.internal.pageSize.getWidth();
          const pdfWidth = doc.internal.pageSize.getWidth() - 2 * bufferX;
          let pageHeight = doc.internal.pageSize.getHeight();
          const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
          let heightLeft = pdfHeight;
          let position = 0;
          doc.addImage(img, "PNG", 15, 15, pdfWidth, pdfHeight);
          heightLeft -= pageHeight;

          // while (heightLeft >= 0) {
          //   position += heightLeft - pdfHeight; // top padding for other pages
          //   doc.addPage();
          //   doc.addImage(
          //     imgData,
          //     "PNG",
          //     15,
          //     position,
          //     pdfWidth,
          //     pdfHeight + 25
          //   );
          //   heightLeft -= pageHeight;
          // }
          // doc.save('download.pdf');
          return doc;
        });

    await  domtoimage
        .toPng(input2)
        .then((imgData2) => {
          let img = new Image();
          img.src = imgData2;
          console.log(imgData2);
          // pdf.addImage(img, 'JPG', 0, 0);
          // pdf.save('download.pdf');

          const bufferX = 15;
          const bufferY = 15;
          const imgProps = doc.getImageProperties(img);
          //const pdfWidth = doc.internal.pageSize.getWidth();
          const pdfWidth = doc.internal.pageSize.getWidth() - 2 * bufferX;
          const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;

          doc.addPage();
          doc.addImage(img, "PNG", 15, 15, pdfWidth, pdfHeight);
          // doc.save('download.pdf');
          return doc;
        });

        await  domtoimage
        .toPng(input3)
        .then((imgData3) => {
          let img = new Image();
          img.src = imgData3;
          console.log(imgData3);
          // pdf.addImage(img, 'JPG', 0, 0);
          // pdf.save('download.pdf');

          const bufferX = 15;
          const bufferY = 15;
          const imgProps = doc.getImageProperties(img);
          //const pdfWidth = doc.internal.pageSize.getWidth();
          const pdfWidth = doc.internal.pageSize.getWidth() - 2 * bufferX;
          const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;

          doc.addPage();
          doc.addImage(img, "PNG", 15, 15, pdfWidth, pdfHeight);
          // doc.save('download.pdf');
          return doc;
        })
        .then((docResult) => {
          setLoading(false);
          docResult.save(
            `${new Date().toLocaleDateString()}_${
              data && data.nombre_completo
            }.pdf`
          );
        });
        
    }

    // const DATA = document.getElementById('printarea');
    // const doc = new jsPDF('p', 'pt', 'a4');
    // const options = {
    //   background: 'white',
    //   scale: 3
    // };
    // html2canvas(DATA, options).then((canvas) => {

    //   const img = canvas.toDataURL('image/PNG');

    //   // Add image Canvas to PDF
    //   const bufferX = 15;
    //   const bufferY = 15;
    //   const imgProps = (doc).getImageProperties(img);
    //   const pdfWidth = doc.internal.pageSize.getWidth() - 2 * bufferX;
    //   const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
    //   doc.addImage(img, 'PNG', bufferX, bufferY, pdfWidth, pdfHeight, undefined, 'FAST');
    //   return doc;
    // }).then((docResult) => {
    //   docResult.save(`${new Date().toISOString()}_tutorial.pdf`);
    // });
  };

  // Firma Medico
  const SaveDrawMedico = (e) => {
    e.preventDefault();
    const data = DrawMedico.current.getSaveData();
    localStorage.setItem("SaveDrawMedico", data);
    toast.success("Firma medico guardada exitosamente");
  };

  const ClearDrawMedico = (e) => {
    e.preventDefault();
    DrawMedico.current.clear();
  };

  const SaveDrawPaciente = (e) => {
    e.preventDefault();
    const data = DrawPaciente.current.getSaveData();
    localStorage.setItem("SaveDrawPaciente", data);
  };

  const SaveDrawConductor = (e) => {
    e.preventDefault();
    const data = DrawConductor.current.getSaveData();
    localStorage.setItem("SaveDrawConductor", data);
  };

  const SaveDrawTripulante = (e) => {
    e.preventDefault();
    const data = DrawTripulante.current.getSaveData();
    localStorage.setItem("SaveDrawTripulante", data);
  };

  //Formik
  const formik = useFormik({
    initialValues: {},
    async onSubmit(values) {
      let paramsToApi = {
        hora_llamada: formik.values.hora_llamada,
        fecha: formik.values.fecha,
        hora_llegada: formik.values.hora_llegada,
        zona: formik.values.zona,
        no_movil: formik.values.no_movil,
        placa_movil: formik.values.placa_movil,
        lugar_origen_ocurrencia: formik.values.lugar_origen_ocurrencia,
        motivo_descripcion_traslado: formik.values.motivo_descripcion_traslado,
        tipo_servicio: formik.values.tipo_servicio,
        ciudad_hc: formik.values.ciudad_hc,
        departamento_hc: formik.values.departamento_hc,
        servicio_prestado: formik.values.servicio_prestado,
        condicion_accidentado: formik.values.condicion_accidentado,
        tipo_vehiculo: formik.values.tipo_vehiculo,
        placa: formik.values.placa,
        marca: formik.values.marca,
        aseguradora: formik.values.aseguradora,
        poliza: formik.values.poliza,
        at: formik.values.at,
        no_poliza: formik.values.no_poliza,
        escala_glassgow: formik.values.escala_glassgow,
        frecuencia_respiratoria: formik.values.frecuencia_respiratoria,
        frecuencia_cardiaca: formik.values.frecuencia_cardiaca,
        saturacion: formik.values.saturacion,
        temperatura: formik.values.temperatura,
        glucometria: formik.values.glucometria,
        oxigeno: formik.values.oxigeno,
        presion_arterial_diastolica: formik.values.presion_arterial_diastolica,
        presion_arterial_sistolica: formik.values.presion_arterial_sistolica,
        analisis_observaciones_traslado:
          formik.values.analisis_observaciones_traslado,
        nombre_institucion_traslado_final:
          formik.values.nombre_institucion_traslado_final,
        servicio_traslado_final: formik.values.servicio_traslado_final,
        habilitacion_cama_traslado_final:
          formik.values.habilitacion_cama_traslado_final,
        ciudad_traslado_final: formik.values.ciudad_traslado_final,
        departamento_traslado_final: formik.values.departamento_traslado_final,
        hora_traslado_final: formik.values.hora_traslado_final,
        fecha_traslado_final: formik.values.fecha_traslado_final,
        autoriza_traslado: formik.values.autoriza_traslado,
        //firma_medico: formik.values.firma_medico,
        firma_medico: localStorage.getItem("SaveDrawMedico"),
        registro_medico: formik.values.registro_medico,
        firma_paciente: formik.values.firma_paciente,
        documento_paciente: formik.values.documento_paciente,
        tripulante_ambulancia: formik.values.tripulante_ambulancia,
        documento_tripulante_ambulancia:
          formik.values.documento_tripulante_ambulancia,
        conductor_ambulancia: formik.values.conductor_ambulancia,
        documento_conductor_ambulancia:
          formik.values.documento_conductor_ambulancia,
      };
      setLoading(true);
      await axios
        .put(
          Config.url + "historia-clinicas/" + urlParams.IdHistoria,
          paramsToApi
        )
        .then((response) => {
          console.log(response);
          toast.success("Cambios guardados exitosamente");
          localStorage.removeItem("SaveDrawMedico");
          localStorage.removeItem("SaveDrawPaciente");
          localStorage.removeItem("SaveDrawTripulante");
          localStorage.removeItem("SaveDrawConductor");
          setLoading(false);
        })
        .catch((error) => {
          console.error(error);
          setLoading(false);
        });

      console.log(values);
    },
  });

  //Functions
  async function BuscarHistoria() {
    setLoading(true);
    const headers = {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("siteToken"),
      },
    };
    await axios
      .get(Config.url + "historia-clinicas/" + urlParams.IdHistoria)
      .then((response) => {
        setLoading(false);
        console.log(response);
        setDataPaciente(response.data.paciente);
        let data = response.data;
        setTimeout(()=>{
          window.print();
        },1000)
        const fields = [
          "id",
          "fecha",
          "zona",
          "no_movil",
          "placa_movil",
          "lugar_origen_ocurrencia",
          "motivo_descripcion_traslado",
          "tipo_servicio",
          "ciudad_hc",
          "departamento_hc",
          "servicio_prestado",
          "condicion_accidentado",
          "tipo_vehiculo",
          "placa",
          "marca",
          "aseguradora",
          "poliza",
          "at",
          "no_poliza",
          "escala_glassgow",
          "frecuencia_respiratoria",
          "frecuencia_cardiaca",
          "saturacion",
          "temperatura",
          "glucometria",
          "oxigeno",
          "presion_arterial_diastolica",
          "presion_arterial_sistolica",
          "analisis_observaciones_traslado",
          "nombre_institucion_traslado_final",
          "servicio_traslado_final",
          "habilitacion_cama_traslado_final",
          "ciudad_traslado_final",
          "departamento_traslado_final",
          "hora_traslado_final",
          "fecha_traslado_final",
          "autoriza_traslado",
          "firma_medico",
          "registro_medico",
          "firma_paciente",
          "documento_paciente",
          "tripulante_ambulancia",
          "documento_tripulante_ambulancia",
          "conductor_ambulancia",
          "documento_conductor_ambulancia",
          "hora_llegada",
          "hora_llamada",
          "hc_cerrada",
        ];
        fields.forEach((field) =>
          formik.setFieldValue(field, data[field], false)
        );
        // setTimeout(() => {
        //   Imprimir(response.data.paciente);
        // }, 100);
      })
      .catch((error) => {
        setLoading(false);
        console.error(error);
      });
  }

  return (
    <>
      {dataPaciente && (
        <div style={{ width: "1080px", margin: "auto" }}>
          

          <div className="container">
            <div id="printarea">
              <div className="row mx-2">
                <div className="col-12" style={{ border: "1px solid", borderRadius: "20px" }}>
                  <div
                    className="row"
                    
                  >
                    <div
                      className="col-3 p-2 text-center"
                      style={{ borderRight: "1px solid" }}
                    >
                      <img
                        src="../../assets/img/logo.png"
                        style={{ width: 150 }}
                      />
                    </div>
                    <div
                      className="col-6 text-center pt-2"
                      style={{ borderRight: "1px solid" }}
                    >
                      <span>FORMATO UNICO DE HISTORIA CLÍNICA</span>
                      <br />
                      <span>
                        <b>EMERMEDICAL 24/7 S.A.S</b>
                      </span>
                      <br />
                      <span> NIT: 900.901.676-1</span>
                      <br />
                      <span> AV Bolivar 35 N 36 - Armenia Quindio </span>
                      <br />
                      <span>
                        {" "}
                        servicioalcliente@sosemermedical.com - 3148473537{" "}
                      </span>
                    </div>
                    <div className="col-3 text-center pt-2">
                      <span>ID HISTORIA </span>
                      <br />
                      {formik.values.id || ""}
                      <br />
                      <br />
                      <span>FECHA IMPRESIÓN </span>
                      <br />
                      {new Date().toLocaleString()}
                    </div>
                  </div>
                </div>
              </div>
              {/* Datos del paciente */}
              <div className="row mt-3">
                <div className="col-12">
                  <div className="card">
                    <div className="card-header">
                      <h5 class="card-title text-center">DATOS DEL PACIENTE</h5>
                    </div>
                    <div className="card-body">
                      <div className="row ">
                        <div className="col-12">
                          <p>
                            <b>Nombre Completo:</b>{" "}
                            {dataPaciente.nombre_completo}
                          </p>
                        </div>
                        <div className="col-4">
                          <p>
                            <b>Documento Nº:</b>
                            {dataPaciente.tipo_documento}{" "}
                            {dataPaciente.numero_documento}
                          </p>
                        </div>
                        <div className="col-4">
                          <p>
                            <b>Sexo:</b> {dataPaciente.sexo}
                          </p>
                        </div>
                        <div className="col-4">
                          <p>
                            <b>Estado Civil:</b> {dataPaciente.estado_civil}
                          </p>
                        </div>
                        <div className="col-4">
                          <p>
                            <b>Fecha de Nacimiento:</b>{" "}
                            {dataPaciente.fecha_nacimiento}
                          </p>
                        </div>
                        <div className="col-4">
                          <p>
                            <b>EPS:</b> {dataPaciente.eps}
                          </p>
                        </div>
                        <div className="col-4">
                          <p>
                            <b>Ocupación:</b> {dataPaciente.ocupacion}
                          </p>
                        </div>

                        <div className="col-4">
                          <p>
                            <b>Teléfono:</b> {dataPaciente.telefono}
                          </p>
                        </div>
                        <div className="col-4">
                          <p>
                            <b>Ciudad:</b> {dataPaciente.ciudad}
                          </p>
                        </div>
                        <div className="col-4">
                          <p>
                            <b>Departamento:</b> {dataPaciente.departamento}
                          </p>
                        </div>
                        <div className="col-4">
                          <p>
                            <b>Dirección:</b> {dataPaciente.direccion}
                          </p>
                        </div>
                        <div className="col-4">
                          <p>
                            <b>Nombre Acompañante:</b>{" "}
                            {dataPaciente.nombre_acompa}
                          </p>
                        </div>
                        <div className="col-4">
                          <p>
                            <b>Parentezco:</b> {dataPaciente.parentesco}
                          </p>
                        </div>
                        <div className="col-4">
                          <p>
                            <b>Diagnóstico de Enfermedades:</b>{" "}
                            {dataPaciente.diagnostico_enfermedades}
                          </p>
                        </div>
                        <div className="col-4">
                          <p>
                            <b>Antecedentes Patológicos:</b>{" "}
                            {dataPaciente.antecedentespatologicos_alergias}
                          </p>
                        </div>
                        <div className="col-4">
                          <p>
                            <b>Familiares:</b>{" "}
                            {dataPaciente.antecedentespatologicos_familiares}
                          </p>
                        </div>
                        <div className="col-4">
                          <p>
                            <b>Cirugias:</b>{" "}
                            {dataPaciente.antecedentespatologicos_cirugias}
                          </p>
                        </div>
                        <div className="col-4">
                          <p>
                            <b>Medicamentos:</b>{" "}
                            {dataPaciente.antecedentespatologicos_medicamentos}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* Datos del traslado */}
              <div className="row mt-3">
                <div className="col-12">
                  <div className="card">
                    <div className="card-header">
                      <h5 class="card-title text-center">DATOS DEL TRASLADO</h5>
                    </div>
                    <div className="card-body">
                      <div className="row ">
                        <div className="col-4">
                          <p>
                            <b>Ciudad:</b> {formik.values.ciudad_hc || ""}
                          </p>
                        </div>
                        <div className="col-4">
                          <p>
                            <b>Departamento:</b>{" "}
                            {formik.values.departamento_hc || ""}
                          </p>
                        </div>
                        <div className="col-4">
                          <p>
                            <b>Hora Llamado:</b>{" "}
                            {formik.values.hora_llamada || ""}
                          </p>
                        </div>
                        <div className="col-4">
                          <p>
                            <b>Fecha:</b> {formik.values.fecha || ""}
                          </p>
                        </div>
                        <div className="col-4">
                          <p>
                            <b>Hora Llegada:</b>{" "}
                            {formik.values.hora_llegada || ""}
                          </p>
                        </div>
                        <div className="col-4">
                          <p>
                            <b>Zona: </b> {formik.values.zona || ""}
                          </p>
                        </div>
                        <div className="col-4">
                          <p>
                            <b>No Móvil:</b> {formik.values.no_movil || ""}
                          </p>
                        </div>
                        <div className="col-4">
                          <p>
                            <b>Placa Móvil:</b>{" "}
                            {formik.values.placa_movil || ""}
                          </p>
                        </div>
                        <div className="col-4">
                          <p>
                            <b>Tipo de Servicio:</b>{" "}
                            {formik.values.tipo_servicio || ""}
                          </p>
                        </div>
                        <div className="col-12">
                          <p>
                            <b>Lugar Origen Y/O Ocurrencia:</b>{" "}
                            {formik.values.lugar_origen_ocurrencia || ""}
                          </p>
                          <p>
                            <b>Motivo y Descripción del traslado:</b>{" "}
                            {formik.values.motivo_descripcion_traslado || ""}
                          </p>

                          <p>
                            <b>Servicio Prestado:</b>{" "}
                            {formik.values.servicio_prestado || ""}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* Datos del accidente de transito */}
              <div className="row mt-3">
                <div className="col-12">
                  <div className="card">
                    <div className="card-header">
                      <h5 class="card-title text-center">
                        DATOS DEL ACCIDENTE DE TRÁNSITO
                      </h5>
                    </div>
                    <div className="card-body">
                      <div className="row ">
                        <div className="col-4">
                          <p>
                            <b>Condición del accidentado:</b>{" "}
                            {formik.values.condicion_accidentado || ""}
                          </p>
                        </div>
                        <div className="col-4">
                          <p>
                            <b>Tipo de Vehículo:</b>{" "}
                            {formik.values.tipo_vehiculo || ""}
                          </p>
                        </div>
                        <div className="col-4">
                          <p>
                            <b>Placa:</b> {formik.values.placa || ""}
                          </p>
                        </div>
                        <div className="col-4">
                          <p>
                            <b>Marca:</b> {formik.values.marca || ""}
                          </p>
                        </div>
                        <div className="col-4">
                          <p>
                            <b>Aseguradora:</b>{" "}
                            {formik.values.aseguradora || ""}
                          </p>
                        </div>
                        <div className="col-4">
                          <p>
                            <b>Póliza:</b> {formik.values.poliza || ""}
                          </p>
                        </div>
                        <div className="col-4">
                          <p>
                            <b>AT:</b> {formik.values.at || ""}
                          </p>
                        </div>
                        <div className="col-4">
                          <p>
                            <b>No:</b> {formik.values.no_poliza || ""}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* Examen físico al inicio del traslado */}
              <div className="row mt-3">
                <div className="col-12">
                  <div className="card">
                    <div className="card-header">
                      <h5 class="card-title text-center">
                        EXAMEN FÍSICO EN INICIO DEL TRASLADO
                      </h5>
                    </div>
                    <div className="card-body">
                      <div className="row ">
                        <div className="col-4">
                          <p>
                            <b>Escala de Glassgow:</b>{" "}
                            {formik.values.escala_glassgow || ""}
                          </p>
                        </div>
                        <div className="col-4">
                          <p>
                            <b>Frec Respiratoria /MIN:</b>{" "}
                            {formik.values.frecuencia_respiratoria || ""}
                          </p>
                        </div>
                        <div className="col-4">
                          <p>
                            <b>Frec Cardiaca /MIN:</b>{" "}
                            {formik.values.frecuencia_cardiaca || ""}
                          </p>
                        </div>
                        <div className="col-4">
                          <p>
                            <b>Saturación de Oxígeno /SPO2:</b>{" "}
                            {formik.values.saturacion || ""}
                          </p>
                        </div>
                        <div className="col-4">
                          <p>
                            <b>Temperatura /Grados:</b>{" "}
                            {formik.values.temperatura || ""}
                          </p>
                        </div>
                        <div className="col-4">
                          <p>
                            <b>Glucometría:</b>{" "}
                            {formik.values.glucometria || ""}
                          </p>
                        </div>
                        <div className="col-4">
                          <p>
                            <b>Oxígeno:</b> {formik.values.oxigeno || ""}
                          </p>
                        </div>
                        <div className="col-4">
                          <p>
                            <b>Presión arterial sistólica:</b>{" "}
                            {formik.values.presion_arterial_sistolica || ""}
                          </p>
                        </div>
                        <div className="col-4">
                          <p>
                            <b>Presión arterial diastólica:</b>{" "}
                            {formik.values.presion_arterial_diastolica || ""}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              
            </div>

            <div id="printarea2">
              {/* ANÁLISIS Y OBSERVACIONES DURANTE EL TRASLADO */}
              <div className="row">
                <div className="col-12">
                  <div className="card">
                    <div className="card-header">
                      <h5 class="card-title text-center">
                        ANÁLISIS Y OBSERVACIONES DURANTE EL TRASLADO
                      </h5>
                    </div>
                    <div className="card-body">
                      <div className="row ">
                        <div className="col-12">
                          <p>
                            <b>Analisis:</b>{" "}
                            {formik.values.analisis_observaciones_traslado ||
                              ""}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* TRASLADO FINAL  IPS/HOSPITAL/CENTRO MÉDICO/ */}
            <div id="printarea3">
              <div className="row" style={{ marginTop: 0 }}>
                <div className="col-12">
                  <div className="card">
                    <div className="card-header">
                      <h5 class="card-title text-center">
                        TRASLADO FINAL IPS/HOSPITAL/CENTRO MÉDICO/
                      </h5>
                    </div>
                    <div className="card-body">
                      <div className="row ">
                        <div className="col-4">
                          <p>
                            <b>Nombre Institucion:</b>{" "}
                            {formik.values.nombre_institucion_traslado_final ||
                              ""}
                          </p>
                        </div>
                        <div className="col-4">
                          <p>
                            <b>Servicio:</b>{" "}
                            {formik.values.servicio_traslado_final || ""}
                          </p>
                        </div>
                        <div className="col-4">
                          <p>
                            <b>Habitación/Cama:</b>{" "}
                            {formik.values.habilitacion_cama_traslado_final ||
                              ""}
                          </p>
                        </div>
                        <div className="col-4">
                          <p>
                            <b>Ciudad:</b>{" "}
                            {formik.values.ciudad_traslado_final || ""}
                          </p>
                        </div>
                        <div className="col-4">
                          <p>
                            <b>Departamento:</b>{" "}
                            {formik.values.departamento_traslado_final || ""}
                          </p>
                        </div>
                        <div className="col-4">
                          <p>
                            <b>Fecha:</b>{" "}
                            {formik.values.fecha_traslado_final || ""}
                          </p>
                        </div>
                        <div className="col-4">
                          <p>
                            <b>Hora:</b>{" "}
                            {formik.values.hora_traslado_final || ""}
                          </p>
                        </div>
                        <div className="col-12">
                          <p>
                            <b>FIRMA DEL MÉDICO:</b>
                            <br />
                            <CanvasDraw
                              ref={DrawMedico}
                              brushColor={"black"}
                              brushRadius={1}
                              lazyRadius={1}
                              canvasWidth={600}
                              canvasHeight={200}
                              disabled
                              hideGrid
                              saveData={formik.values.firma_medico ? formik.values.firma_medico : null }
                            />
                          </p>
                        </div>
                        <div className="col-12">
                          <p>
                            <b>Documento o Registro médico:</b>{" "}
                            {formik.values.registro_medico || ""}
                          </p>
                        </div>
                        <div className="col-12">
                          <p>
                            <b>Consentimiento Informado:</b> He comprendido lo
                            que se me ha explicado; doy mi consentimiento y
                            autorizo al equipo correspondiente para llevar a
                            cabo el traslado y efectuar los procedimientos
                            terapéuticos que se consideren indicados. Sé también
                            que puedo retractarme de la firma de este
                            consentimiento previo al traslado, haciendo mención
                            expresa de ello y firmando al lado de la firma del
                            consentimiento
                          </p>
                        </div>
                        <div className="col-12">
                          <p>
                            <b>Autorizo Traslado:</b>{" "}
                            {formik.values.autoriza_traslado}
                          </p>
                        </div>
                        <div className="col-12">
                          <p>
                            <b>FIRMA DEL PACIENTE / ACOMPAÑANTE:</b>
                            <br />
                            <CanvasDraw
                              ref={DrawPaciente}
                              brushColor={"black"}
                              brushRadius={1}
                              lazyRadius={1}
                              canvasWidth={600}
                              canvasHeight={200}
                              disabled
                              hideGrid
                              saveData={formik.values.firma_paciente ? formik.values.firma_paciente : null }
                            />
                          </p>
                        </div>
                        <div className="col-12">
                          <p>
                            <b>Documento Paciente / Acompañante:</b>{" "}
                            {formik.values.documento_paciente || ""}
                          </p>
                        </div>
                        <div className="col-12">
                          <p>
                            <b>FIRMA DEL TRIPULANTE DE LA AMBULANCIA:</b>
                            <br />
                            <CanvasDraw
                              ref={DrawTripulante}
                              brushColor={"black"}
                              brushRadius={1}
                              lazyRadius={1}
                              canvasWidth={600}
                              canvasHeight={200}
                              disabled
                              hideGrid
                              saveData={formik.values.tripulante_ambulancia ? formik.values.tripulante_ambulancia : null }
                            />
                          </p>
                        </div>
                        <div className="col-12">
                          <p>
                            <b>Documento Tripulante Ambulancia:</b>{" "}
                            {formik.values.documento_tripulante_ambulancia ||
                              ""}
                          </p>
                        </div>
                        <div className="col-12">
                          <p>
                            <b>FIRMA DEL CONDUCTOR DE LA AMBULANCIA:</b>
                            <br />
                            <CanvasDraw
                              ref={DrawConductor}
                              brushColor={"black"}
                              brushRadius={1}
                              lazyRadius={1}
                              canvasWidth={600}
                              canvasHeight={200}
                              disabled
                              hideGrid
                              saveData={formik.values.conductor_ambulancia ? formik.values.conductor_ambulancia : null }
                            />
                          </p>
                        </div>
                        <div className="col-12">
                          <p>
                            <b>Documento Conductor Ambulancia:</b>{" "}
                            {formik.values.documento_conductor_ambulancia || ""}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            
          </div>
          
        </div>
      )}
    </>
  );
}

export default ImprimirHistoria;
