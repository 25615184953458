import React from 'react';

function Dashboard(){
    return(
        <>
        <div className="row layout-top-spacing">
        <div className="col-xl-12 col-lg-12 col-md-12 col-12 layout-spacing">
          <div className="widget-content-area br-4">
            <div className="widget-one">
              <h6>Sistema de historias clínicas - emermedical!</h6>
            </div>
          </div>
        </div>
      </div>
        </>
    );
}

export default Dashboard;