import React from "react";
import { Nav, Navbar, Container, Row, Col, Card, Button, NavDropdown } from "react-bootstrap";
import { withRouter } from "react-router";
import { Link } from 'react-router-dom';
function MainLayout({children}){
return(

    <>
    <Navbar collapseOnSelect expand="lg" bg="dark" variant="dark">
  <Navbar.Brand href="#">Emermedical</Navbar.Brand>
  <Navbar.Toggle aria-controls="responsive-navbar-nav" />
  <Navbar.Collapse id="responsive-navbar-nav">
    <Nav className="mr-auto">
      <Link className="nav-link" to="/dashboard">
      Dashboard
      </Link>
      <Link className="nav-link" to="/pacientes/listar">
      Pacientes
      </Link>
      <Link className="nav-link" to="/historias/listar">
      Historias Clínicas
      </Link>
    </Nav>
    <Nav>
      <Nav.Link href="/logout">Salir</Nav.Link>
    </Nav>
  </Navbar.Collapse>
</Navbar>
<Container fluid>
                
                <Row className="mt-2">
                <Col>
                        {children}
                    </Col> 
                </Row>

            </Container>
    </>
    
);
}

export default MainLayout;