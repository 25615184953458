
import React from "react";
import axios from "axios";
import Config from "../../../Config";
import { Navbar, Container, Row, Col, Card, Button } from "react-bootstrap";
import {logo} from '../../../assets/img/logo.png';
import { useParams } from "react-router-dom";
function Dpx(props) {
    const [data, setData] = React.useState(null);
    const params = useParams();
    
    React.useEffect(()=>{

        ObtenerPacientes();

    },[])

    async function ObtenerPacientes(){

        await axios.get(Config.url +'pacientes/'+params.id, { headers: { 'Authorization': 'Bearer ' + localStorage.getItem('token') } } )
        .then(response=>{
            console.log(response);
            setData(response.data);
        })
        .catch(error =>{
            console.log(error);
        })

    }

    return(
        <>
        <Navbar bg="light">
    <Navbar.Brand>
      <img src="../../../assets/img/logo.png" alt="emermedical logo" style={{width:150}} className="ml-2 d-block mx-auto" />
    </Navbar.Brand>
    
  </Navbar>
  <Container>
    <Row>
      <Col xs={12} >
        <Card>
          <Card.Body>
            <Row style={{textAlign: 'center'}}>
            <Col md={10} className="verticalcenter">
            <h1>
              <br/>
              {data && 
              <>
              {data.nombre_completo} - {data.tipo_documento} {data.numero_documento}
              </>
              }
            </h1>
            </Col>
            </Row>
          </Card.Body>
        </Card>
      </Col>
    </Row>
    <br/>
    <Row>
      <Col xs={12}>
      <Card>
          <Card.Body>
            
            <Row style={{textAlign: 'center'}}>
              <Col xs={6}>
              <strong>Estado del servicio</strong>
              </Col>
              <Col xs={6}>
              <strong>Expira</strong>
              </Col>
            </Row>
            <Row style={{textAlign: 'center'}}>
              <Col xs={6}>
              Activo
              </Col>
              <Col xs={6}>
              {data && new Date().toLocaleDateString('es-ES')}
              </Col>
            </Row>
            </Card.Body>
            </Card>

      </Col>
    </Row>
  </Container>
        </>
    )
}
export default Dpx;